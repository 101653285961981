import React from "react"
import { Grid, Typography } from "@mui/material/"
import FOIACasePage from "./content/FOIA"

export default function FOIA() {
  return (
    <Grid
      container
      xs={12}
      sm={12}
      md={12}
      lg={12}
      spacing={3}
      className="foiaContainer"
    >
      <Grid item xs={12} className="pageTitle">
        <Typography variant="h5">Create FOIA Case</Typography>
      </Grid>

      <Grid item xs={6} className="marginCenter">
        <FOIACasePage />
      </Grid>
    </Grid>
  )
}

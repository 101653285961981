import React from "react";
import { Grid, Typography } from "@mui/material/";
import SearchContent from "./content/search";

export default function FOIARequestCaseSearch() {
  return (
    <Grid
      container
      xs={12}
      sm={12}
      md={12}
      lg={12}
      spacing={3}
      className="fOIARequestCaseSearchContainer"
    >
      <Grid item xs={12} className="pageTitle">
        <Typography variant="h5">FOIA Request Case Search Page</Typography>
      </Grid>

      <Grid item xs={12} className="marginCenter">
        <SearchContent />
      </Grid>
    </Grid>
  );
}

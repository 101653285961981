import * as React from 'react'
import Box from '@mui/material/Box'
import {
    Slider,
    Typography
} from '@mui/material/'

const marks = [
  {
    value: 0,
    label: '0',
  },
  {
    value: .1,
    label: '.1',
  },
  {
    value: .2,
    label: '.2',
  },
  {
    value: .3,
    label: '.3',
  },
  {
    value: .4,
    label: '.4',
  },
  {
    value: .5,
    label: '.5',
  },
  {
    value: .6,
    label: '.6',
  },
  {
    value: .7,
    label: '.7',
  },
  {
    value: .8,
    label: '.8',
  },
  {
    value: .9,
    label: '.9',
  },
  {
    value: 1,
    label: '1',
  }
]


export default function ConfidenceSlider(props) {

    const handleSliderChange = (event, newValue) => {
   
        props.retrieveConfidenceGage(newValue)
    }

  return (
    <Box sx={{ width: 500, marginTop: '50px' }}>
    <Typography gutterBottom sx={{textAlign: 'center', color: '#016bb5'}} >Minimum Confidence Level</Typography>
    
      <Slider
         aria-label="Confidence Level"
         defaultValue={0}
         step={.01}
         min={0}
         max={1}
         marks={marks}
         valueLabelDisplay="on"
         onChange={handleSliderChange}

      />
    </Box>
  );
}
import React from "react";
import { Grid } from "@mui/material";
import { MenuComponent } from "../components/index";
import CENTCOM from "../assets/images/CENTCOM.png";

const Header = () => {
  return (
    <div>
      <Grid container xs={12} sm={12} md={12} lg={12}>
        <Grid xs={12} className="rightAlign">
          <a href={"/"}>
            <img src={CENTCOM} alt="CENTCOM Logo" />
          </a>
          <MenuComponent />
        </Grid>
      </Grid>
    </div>
  );
};

export default Header;

import * as React from "react"
import PropTypes from "prop-types"
import CircularProgress from "@mui/material/CircularProgress"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import { format, addDays } from 'date-fns'

function CircularProgressWithLabel(props) {

  const calcDate = () => {
     const MAX_DAYS = 23
    let receivedDay
    let daysPass
    let curDate
    let remaingTime
    let totalDays
    let formatTotalDays 

    if(props.date !== undefined){
        receivedDay = parseInt(format(new Date(props.date), "dd"))
        totalDays = addDays(new Date(receivedDay), MAX_DAYS)
        formatTotalDays =  parseInt(format(new Date(totalDays), "dd"))

        daysPass = (formatTotalDays - receivedDay)
        curDate = parseInt(format(new Date(), "dd"))
    
        remaingTime = ((( formatTotalDays - daysPass) / formatTotalDays) * 100)
        console.log(remaingTime)
    }

    return remaingTime
  }

  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="text.primary">
          {`${Math.round(calcDate())}%`}
        </Typography>

      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

export default function CircularStatic(props) {
  const [progress, setProgress] = React.useState(10);

    const calcDate = () => {
     const MAX_DAYS = 23
    let receivedDay
    let daysPass
    let curDate
    let remaingTime
    let totalDays
    let formatTotalDays 

    if(props.receivedDate !== undefined){
        receivedDay = parseInt(format(new Date(props.receivedDate), "dd"))
        totalDays = addDays(new Date(receivedDay), MAX_DAYS)
        formatTotalDays =  parseInt(format(new Date(totalDays), "dd"))

        daysPass = (formatTotalDays - receivedDay)
        curDate = parseInt(format(new Date(), "dd"))
    
        remaingTime = ((( formatTotalDays - daysPass) / formatTotalDays) * 100)
        console.log(remaingTime)
    }

    return remaingTime
  }


  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
       Math.round(calcDate())// prevProgress < 70 ? prevProgress + 10 : 70
      );
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <span>
      <CircularProgressWithLabel value={progress} date={props.receivedDate} />
      <Typography sx={{ color: "#016bb5", fontSize: "14px" }}>
        Completed
      </Typography>
    </span>
  );
}

import React, { useState, useEffect } from "react"

import {
  Grid,
  Paper
} from "@mui/material/"

import {getFoiaCaseData,
         getCaseDetails
} from "../service/services"
import {ReadableTable, SearchTable} from "../components/table"
import { Typography } from "@mui/material"

export default function CaseDetails(props) {
  useEffect(() => {
    retrieveData()

  
    
}, [])


const [state, setState] = useState({
  dataValues: [],
  caseData: {}
  
})

const [caseDetailsData, setCaseDetailsData] = React.useState("")
const [caseNumber, setCaseNumber] = React.useState("")
const [tableData, setTableData] = React.useState("")


  // Service Calls
  const retrieveData = () => {
    getFoiaCaseData()
      .then((response) => {
        setState({
          dataValues: response.data.cases,
        })
       
 
    
        console.log(response.data.cases)
      })
      .catch((e) => {
        console.log(e)
      })
   }


  return (
    <div className="foiaCaseDetailContainer" > 

   
      <Grid container xs={12} spacing="12" >


            <Grid item xs={11} className="marginCenter" >
            
                <SearchTable
                  data={state.dataValues}             
                     
                />  
               
            </Grid>

            <div className="spacer" />

            <Grid item xs={11} className="marginCenter" style={{height: '200px'}} >
              <div className="spacer" />
              <div className="spacer" />
            </Grid>

        </Grid> 

    </div>
  );
}

import React, { useState } from "react";
import { List, Checkbox, ListItem, ListItemButton } from "@mui/material/";

import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import { FILE_ICON } from "../assets/icons/IconList";
import Typography from "@mui/material/Typography"
import PDF from "../assets/icons/PDF.png";
import DOCX from "../assets/icons/DOCX.png";
import TXT from "../assets/icons/TXT.png";

function QueryResultsList(props) {
  const [checked, setChecked] = React.useState([1]);
  const [selectedFiles, setSelectedFiles] = React.useState([]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    setChecked(newChecked);

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    selectedFiles.push(newChecked[1].Meta.path);

    setSelectedFiles((current) => [...current, newChecked[1].Meta.path]);

    setChecked(newChecked);

    {
      props.retrieveFileSelection(newChecked[1].Meta.path);
    }
  };

  const truncateContent = (str) => {
    str = JSON.stringify(str);
    return str.length > 10 ? str.substring(2, 265) + "..." : str;
  };

  const labelId = `checkbox-list-secondary-label-${props.Data}`;

  return (
    <List sx={{ width: "100%" }} className="fadePanel">
      <ListItem
        alignItems="flex-start"
        secondaryAction={
          <Checkbox
            edge="end"
            onClick={handleToggle(props.Data)}
            checked={checked.indexOf(props.Data) !== -1}
            inputProps={{ "aria-labelledby": labelId }}
          />
        }
      >
        <ListItemAvatar>
          <FILE_ICON />
        </ListItemAvatar>

        <ListItemText
          primary={
            <a
              href={"https://centcomservices.ais.saicds.com:8080" + props.path}
              target="_blank"
            >
              {props.name}
            </a>
          }
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                Download
              </Typography>
            </React.Fragment>
          }
        />
      </ListItem>

      <ListItem alignItems="flex-start">
        <ListItemText
          primary={<span>Organization: &nbsp; {props.organization}</span>}
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                <br />
                {/* {props.content} */}
                {truncateContent(props.content)}
              </Typography>
            </React.Fragment>
          }
        />
      </ListItem>
      <br />
    </List>
  );
}

function SearchResultsList(props) {
  const [checked, setChecked] = React.useState([1]);
  const [selectedFiles, setSelectedFiles] = React.useState([]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    setChecked(newChecked);

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    selectedFiles.push(newChecked[1].Meta.path);

    setSelectedFiles((current) => [...current, newChecked[1].Meta.path]);

    setChecked(newChecked);

    {
      props.retrieveFileSelection(newChecked[1].Meta.path);
    }
  };

  const labelId = `checkbox-list-secondary-label-${props.Data}`;

  return (
    <List sx={{ width: "100%" }} className="fadePanel">
      <ListItem
        alignItems="flex-start"
        secondaryAction={
          <Checkbox
            edge="end"
            onClick={handleToggle(props.Data)}
            checked={checked.indexOf(props.Data) !== -1}
            inputProps={{ "aria-labelledby": labelId }}
          />
        }
      >
        <ListItemAvatar>
          <FILE_ICON />
        </ListItemAvatar>

        <ListItemText
          primary={
            <a
              href={
                "https://centcomservices.ais.saicds.com:8080" +
                props.Data.Meta.path
              }
              target="_blank"
            >
              {props.FileName}
            </a>
          }
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                Download
              </Typography>
            </React.Fragment>
          }
        />
      </ListItem>

      <ListItem alignItems="flex-start">
        <ListItemText
          primary={<span>Confidence: &nbsp; {props.Score}</span>}
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                <br />
              {props.Context}
             
              </Typography>
            </React.Fragment>
          }
        />
      </ListItem>
      <br />
    </List>
  );
}

function CaseFileResultsList(props) {
  const truncateContent = (str) => {
    str = JSON.stringify(str);
    return str.length > 10 ? str.substring(2, 100) + "..." : str;
  };

  const fileType = (fileName) => {
    let icon;
    if (fileName.split(".").pop() === "pdf") {
      icon = PDF;
    }

    if (fileName.split(".").pop() === "docx") {
      icon = DOCX;
    }

    if (fileName.split(".").pop() === "txt") {
      icon = TXT;
    }

    return icon;
  };

  return (
    <span>
      {props.files === undefined
        ? ""
        : props.files.map((item) => {
            return (
              <List>
                <ListItem>
                  <ListItemButton>
                    <ListItemText
                      secondary={<img src={fileType(item)} width="30px" />}
                      primary={
                        <span>
                          <a
                            href={
                              "https://centcomservices.ais.saicds.com:8080" +
                              item
                            }
                            target="_blank"
                          >
                            {truncateContent(item)}
                          </a>
                        </span>
                      }
                      maxLine="1"
                      ellipsis="..."
                      trimRight
                      basedOn="letters"
                    />
                  </ListItemButton>
                </ListItem>
                <div className="dividerShape2" />
              </List>
            );
          })}
    </span>
  );
}

export { QueryResultsList, SearchResultsList, CaseFileResultsList };

import React from "react"
import {
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText
 } from "@mui/material/"

export default function StatusIndicator(props) {
  const indicator = () => {
    let statusCode
    let pulseGreen
    let pulseOrange
    let pulseRed

    if(props.status === "Open"){
      statusCode = "#47ED62"
      pulseGreen = true
    }

    if(props.status === "Closed"){
   
      statusCode = "#FD5F5F"
      pulseRed = true
    }

    if(props.status === "Hold"){
      statusCode = "#E5D43A"
      pulseOrange = true
    }


    return (
    
          <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'transparent',fontSize: '40px'}}>
          <ListItem>
            <ListItemAvatar>
                {
                  props.status === "Open" ?  <span className="statusGreenPulse" style={pulseGreen ? { display:'block'} : {display : 'none'}} /> : ''
                }

                {
                  props.status === "Hold" ?  <span className="statusOrangePulse"  style={pulseOrange ? { display:'block'} : {display : 'none'}} /> : ''
                }

                {
                  props.status === "Closed" ?  <span className="statusRedPulse" style={pulseRed ? { display:'block'} : {display : 'none'}} /> : ''
                }
            </ListItemAvatar>
            <ListItemText style={{color:statusCode}} primary={props.status}  />
          </ListItem>
        </List>
    )
  } 

  return indicator()
}




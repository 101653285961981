import React from 'react'
import { Grid } from "@mui/material"
import Header from "./header"
import Footer from "./footer"

export default ({ children, ...props } = {}) => (
  <Grid container item {...props} className="fadePanel" >

    <Grid item xs={12} sm={12} md={12} lg={12} className="headerContainer">
      <Header />
    </Grid>

    <Grid item xs={11} sm={11} md={12} lg={12} className="contentContainer fadePanel">
      {children}
    </Grid>

    <Grid item xs={11} sm={11} md={12} lg={12} className="footerContainer">
      <Footer />
    </Grid>

  </Grid>
)

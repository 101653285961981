import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles"
import {
  Grid,
  Typography,
  TextField,
  Stack,
  ListItem,
  Chip,
  Button,
  FormControl,
  FormControlLabel
} from "@mui/material/";
import { getFoiaCaseData, getCaseDetails } from "../../service/services"
import StatusIndicator from "../../components/statusIndicator"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { format } from 'date-fns'
import { FILE_ICON, BACK_ARROW } from "../../assets/icons/IconList";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import {CaseDashboardTable} from "../../components/table"
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[2],
    fontSize: 18,
  },
}));

const BASE_URL = process.env.REACT_APP_BASE_URL;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function CaseDetails(props) {

  const [caseSelected, setSelectedCase] = React.useState("");
  const [caseDetailData, setCaseDetailData] = React.useState([]);
  const [caseNumber, setCaseNumber] = React.useState("");
  const [foiaRequest, setFoiaRequest] = React.useState("");
  const [customEntities, setCustomEntities] = React.useState("");
  const [entities, setEntities] = React.useState([]);
  const [entityName, setEntityName] = React.useState([]);
  const [assignToUser, setAssignToUser] = React.useState("Assign to User");
  const [requestedByUser, setRequestedByUser] = React.useState("");
  const [caseClosed, setCaseClosed] = React.useState();
  const [dateReceived, setDateReceived] = React.useState();
  const [nersName, setNersName] = React.useState([]);
  const [chipData, setChipData] = React.useState([""]);
  const [processingCase, setProcessingCase] = React.useState(false);
  const [loadingEntities, setLoadingEntities] = React.useState(false);
  const [processMessage, setProcessMessage] = React.useState(false);

  useEffect(() => {
    retrieveCaseDetails(id);


  }, [caseDetailData]);

  const [state, setState] = useState({
    dataValues: [],
    caseData: {},
    cases: [],
    isSingleWord: false,
    whichSelectList: "entityType",
  });

  const theme = useTheme();



  // Service Call
  const retrieveCaseDetails = (caseNumber) => {
   

    getCaseDetails(caseNumber)
      .then((response) => {
       
        setCaseDetailData(response.data.case)


      })
      .catch((e) => {
        console.log(e);
      });
  }

  const preloadedValues = {
        firstName: caseDetailData.firstname,
        lastName: caseDetailData.lastname,
        // address: caseDetailData.address,
        // city: caseDetailData.city,
        // state: caseDetailData.state,
        // country: caseDetailData.country,
        // zip: caseDetailData.zip,
        // phone: caseDetailData.phone,
        // email: caseDetailData.email
    
  }

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      console.log(preloadedValues)
      return preloadedValues
    }, [props])
  });

  // Handlers
  // const handleAssignedUser = (user) => {
  //   console.log(user);
  //   setAssignToUser(user);
  // }

  const checkFieldInput = ( type, newInputValue, oldInputValue) => {

    const inputValues = [
      'firstName',
      'lastName',
      'address',
      'city',
      'state',
      'zip',
      'country',
      'email',
      'phone'
    ]

    let newInput
    let oldInput 

    let loadValue 

    inputValues.map((item)=>{
    
      if(type === item){
       console.log(item)
          newInput = newInputValue
          oldInput = oldInputValue
    
      }
    })


    if(newInput === ""  ){
      
      loadValue = "Field"
    }

    if(oldInput === ""  ){
      
      loadValue = "Field"
    }

    if(newInput){
 
      loadValue = newInput
    }

    if(oldInput &&  newInput === ""){
     
      loadValue = oldInput
    }

    return loadValue


  }

  const onSubmit = (data) => {
    console.log("Processing New Case", data)

    let firstNamePreload 
    let lastNamePreload
    let addressPreload
    let cityPreload
    let zipPreload
    let countryPreload
    let statePreload
    let phonePreload
    let emailPreload

    firstNamePreload = checkFieldInput('firstName', data.firstName, caseDetailData.firstname)
    lastNamePreload = checkFieldInput('lastName', data.lastName, caseDetailData.lastname)
    addressPreload = checkFieldInput('address', data.address, caseDetailData.address)
    cityPreload = checkFieldInput('city', data.city, caseDetailData.city)
    zipPreload = checkFieldInput('zip', data.zip, caseDetailData.zip)
    countryPreload = checkFieldInput('country', data.country, caseDetailData.country)
    statePreload = checkFieldInput('state', data.state, caseDetailData.state)
    phonePreload = checkFieldInput('phone', data.phone, caseDetailData.phone)
    emailPreload = checkFieldInput('email', data.email, caseDetailData.email)

    console.log("First Name:",firstNamePreload )
    console.log("Last Name:",lastNamePreload )
    console.log("Address:",addressPreload )
    console.log("City:",cityPreload )
    console.log("Zip:",zipPreload )
    console.log("Country:",countryPreload )
    console.log("State:",statePreload )
    console.log("Phone:",phonePreload )
    console.log("Email:",emailPreload )

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    setProcessingCase(true);
    setCaseNumber(data.caseNumber);

    axios
      .put(`${BASE_URL}cases/`, {
        casenumber: caseDetailData.casenumber,
        casefiles: caseDetailData.casefiles,
        foiarequest: caseDetailData.foiarequest,
        associatedentities: caseDetailData.associatedentities,
        status: caseDetailData.status,
        caseclosed: caseDetailData.caseclosed,
        assigneduser: caseDetailData.assigneduser,
        receivedon: caseDetailData.receivedon,
        requestedby: caseDetailData.requestedby,
        firstname: firstNamePreload,
        lastname: lastNamePreload,
        address: addressPreload,
        city: cityPreload,
        state: statePreload,
        country: countryPreload,
        zip: zipPreload,
        phone: phonePreload,
        email: emailPreload
      })
      .then((response) => {
        console.log(response.data);
        setTimeout(() => {
          setProcessingCase(false);
          setProcessMessage(true);
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
      })

  }

  const [checked, setChecked] = React.useState([1]);

  // const handleToggle = (value) => () => {
  //   const currentIndex = checked.indexOf(value);
  //   const newChecked = [...checked];

  //   if (currentIndex === -1) {
  //     newChecked.push(value);
  //   } else {
  //     newChecked.splice(currentIndex, 1);
  //   }

  //   setChecked(newChecked);
  // };

  // const handleChipDelete = (chipToDelete) => {
  //   console.info("Chip Deleted", chipToDelete);

  //   setCaseDetailData((chips) =>
  //     chips.filter((chip) => chip.key === chipToDelete.key)
  //   );
  // };

  const { id } = useParams();

  const countries = [
    { code: "AD", label: "Andorra", phone: "376" },
    {
      code: "AE",
      label: "United Arab Emirates",
      phone: "971",
    },
    { code: "AF", label: "Afghanistan", phone: "93" },
    {
      code: "AG",
      label: "Antigua and Barbuda",
      phone: "1-268",
    },
    { code: "AI", label: "Anguilla", phone: "1-264" },
    { code: "AL", label: "Albania", phone: "355" },
    { code: "AM", label: "Armenia", phone: "374" },
    { code: "AO", label: "Angola", phone: "244" },
    { code: "AQ", label: "Antarctica", phone: "672" },
    { code: "AR", label: "Argentina", phone: "54" },
    { code: "AS", label: "American Samoa", phone: "1-684" },
    { code: "AT", label: "Austria", phone: "43" },
    {
      code: "AU",
      label: "Australia",
      phone: "61",
      suggested: true,
    },
    { code: "AW", label: "Aruba", phone: "297" },
    { code: "AX", label: "Alland Islands", phone: "358" },
    { code: "AZ", label: "Azerbaijan", phone: "994" },
    {
      code: "BA",
      label: "Bosnia and Herzegovina",
      phone: "387",
    },
    { code: "BB", label: "Barbados", phone: "1-246" },
    { code: "BD", label: "Bangladesh", phone: "880" },
    { code: "BE", label: "Belgium", phone: "32" },
    { code: "BF", label: "Burkina Faso", phone: "226" },
    { code: "BG", label: "Bulgaria", phone: "359" },
    { code: "BH", label: "Bahrain", phone: "973" },
    { code: "BI", label: "Burundi", phone: "257" },
    { code: "BJ", label: "Benin", phone: "229" },
    { code: "BL", label: "Saint Barthelemy", phone: "590" },
    { code: "BM", label: "Bermuda", phone: "1-441" },
    { code: "BN", label: "Brunei Darussalam", phone: "673" },
    { code: "BO", label: "Bolivia", phone: "591" },
    { code: "BR", label: "Brazil", phone: "55" },
    { code: "BS", label: "Bahamas", phone: "1-242" },
    { code: "BT", label: "Bhutan", phone: "975" },
    { code: "BV", label: "Bouvet Island", phone: "47" },
    { code: "BW", label: "Botswana", phone: "267" },
    { code: "BY", label: "Belarus", phone: "375" },
    { code: "BZ", label: "Belize", phone: "501" },
    {
      code: "CA",
      label: "Canada",
      phone: "1",
      suggested: true,
    },
    {
      code: "CC",
      label: "Cocos (Keeling) Islands",
      phone: "61",
    },
    {
      code: "CD",
      label: "Congo, Democratic Republic of the",
      phone: "243",
    },
    {
      code: "CF",
      label: "Central African Republic",
      phone: "236",
    },
    {
      code: "CG",
      label: "Congo, Republic of the",
      phone: "242",
    },
    { code: "CH", label: "Switzerland", phone: "41" },
    { code: "CI", label: "Cote d'Ivoire", phone: "225" },
    { code: "CK", label: "Cook Islands", phone: "682" },
    { code: "CL", label: "Chile", phone: "56" },
    { code: "CM", label: "Cameroon", phone: "237" },
    { code: "CN", label: "China", phone: "86" },
    { code: "CO", label: "Colombia", phone: "57" },
    { code: "CR", label: "Costa Rica", phone: "506" },
    { code: "CU", label: "Cuba", phone: "53" },
    { code: "CV", label: "Cape Verde", phone: "238" },
    { code: "CW", label: "Curacao", phone: "599" },
    { code: "CX", label: "Christmas Island", phone: "61" },
    { code: "CY", label: "Cyprus", phone: "357" },
    { code: "CZ", label: "Czech Republic", phone: "420" },
    {
      code: "DE",
      label: "Germany",
      phone: "49",
      suggested: true,
    },
    { code: "DJ", label: "Djibouti", phone: "253" },
    { code: "DK", label: "Denmark", phone: "45" },
    { code: "DM", label: "Dominica", phone: "1-767" },
    {
      code: "DO",
      label: "Dominican Republic",
      phone: "1-809",
    },
    { code: "DZ", label: "Algeria", phone: "213" },
    { code: "EC", label: "Ecuador", phone: "593" },
    { code: "EE", label: "Estonia", phone: "372" },
    { code: "EG", label: "Egypt", phone: "20" },
    { code: "EH", label: "Western Sahara", phone: "212" },
    { code: "ER", label: "Eritrea", phone: "291" },
    { code: "ES", label: "Spain", phone: "34" },
    { code: "ET", label: "Ethiopia", phone: "251" },
    { code: "FI", label: "Finland", phone: "358" },
    { code: "FJ", label: "Fiji", phone: "679" },
    {
      code: "FK",
      label: "Falkland Islands (Malvinas)",
      phone: "500",
    },
    {
      code: "FM",
      label: "Micronesia, Federated States of",
      phone: "691",
    },
    { code: "FO", label: "Faroe Islands", phone: "298" },
    {
      code: "FR",
      label: "France",
      phone: "33",
      suggested: true,
    },
    { code: "GA", label: "Gabon", phone: "241" },
    { code: "GB", label: "United Kingdom", phone: "44" },
    { code: "GD", label: "Grenada", phone: "1-473" },
    { code: "GE", label: "Georgia", phone: "995" },
    { code: "GF", label: "French Guiana", phone: "594" },
    { code: "GG", label: "Guernsey", phone: "44" },
    { code: "GH", label: "Ghana", phone: "233" },
    { code: "GI", label: "Gibraltar", phone: "350" },
    { code: "GL", label: "Greenland", phone: "299" },
    { code: "GM", label: "Gambia", phone: "220" },
    { code: "GN", label: "Guinea", phone: "224" },
    { code: "GP", label: "Guadeloupe", phone: "590" },
    { code: "GQ", label: "Equatorial Guinea", phone: "240" },
    { code: "GR", label: "Greece", phone: "30" },
    {
      code: "GS",
      label: "South Georgia and the South Sandwich Islands",
      phone: "500",
    },
    { code: "GT", label: "Guatemala", phone: "502" },
    { code: "GU", label: "Guam", phone: "1-671" },
    { code: "GW", label: "Guinea-Bissau", phone: "245" },
    { code: "GY", label: "Guyana", phone: "592" },
    { code: "HK", label: "Hong Kong", phone: "852" },
    {
      code: "HM",
      label: "Heard Island and McDonald Islands",
      phone: "672",
    },
    { code: "HN", label: "Honduras", phone: "504" },
    { code: "HR", label: "Croatia", phone: "385" },
    { code: "HT", label: "Haiti", phone: "509" },
    { code: "HU", label: "Hungary", phone: "36" },
    { code: "ID", label: "Indonesia", phone: "62" },
    { code: "IE", label: "Ireland", phone: "353" },
    { code: "IL", label: "Israel", phone: "972" },
    { code: "IM", label: "Isle of Man", phone: "44" },
    { code: "IN", label: "India", phone: "91" },
    {
      code: "IO",
      label: "British Indian Ocean Territory",
      phone: "246",
    },
    { code: "IQ", label: "Iraq", phone: "964" },
    {
      code: "IR",
      label: "Iran, Islamic Republic of",
      phone: "98",
    },
    { code: "IS", label: "Iceland", phone: "354" },
    { code: "IT", label: "Italy", phone: "39" },
    { code: "JE", label: "Jersey", phone: "44" },
    { code: "JM", label: "Jamaica", phone: "1-876" },
    { code: "JO", label: "Jordan", phone: "962" },
    {
      code: "JP",
      label: "Japan",
      phone: "81",
      suggested: true,
    },
    { code: "KE", label: "Kenya", phone: "254" },
    { code: "KG", label: "Kyrgyzstan", phone: "996" },
    { code: "KH", label: "Cambodia", phone: "855" },
    { code: "KI", label: "Kiribati", phone: "686" },
    { code: "KM", label: "Comoros", phone: "269" },
    {
      code: "KN",
      label: "Saint Kitts and Nevis",
      phone: "1-869",
    },
    {
      code: "KP",
      label: "Korea, Democratic People's Republic of",
      phone: "850",
    },
    { code: "KR", label: "Korea, Republic of", phone: "82" },
    { code: "KW", label: "Kuwait", phone: "965" },
    { code: "KY", label: "Cayman Islands", phone: "1-345" },
    { code: "KZ", label: "Kazakhstan", phone: "7" },
    {
      code: "LA",
      label: "Lao People's Democratic Republic",
      phone: "856",
    },
    { code: "LB", label: "Lebanon", phone: "961" },
    { code: "LC", label: "Saint Lucia", phone: "1-758" },
    { code: "LI", label: "Liechtenstein", phone: "423" },
    { code: "LK", label: "Sri Lanka", phone: "94" },
    { code: "LR", label: "Liberia", phone: "231" },
    { code: "LS", label: "Lesotho", phone: "266" },
    { code: "LT", label: "Lithuania", phone: "370" },
    { code: "LU", label: "Luxembourg", phone: "352" },
    { code: "LV", label: "Latvia", phone: "371" },
    { code: "LY", label: "Libya", phone: "218" },
    { code: "MA", label: "Morocco", phone: "212" },
    { code: "MC", label: "Monaco", phone: "377" },
    {
      code: "MD",
      label: "Moldova, Republic of",
      phone: "373",
    },
    { code: "ME", label: "Montenegro", phone: "382" },
    {
      code: "MF",
      label: "Saint Martin (French part)",
      phone: "590",
    },
    { code: "MG", label: "Madagascar", phone: "261" },
    { code: "MH", label: "Marshall Islands", phone: "692" },
    {
      code: "MK",
      label: "Macedonia, the Former Yugoslav Republic of",
      phone: "389",
    },
    { code: "ML", label: "Mali", phone: "223" },
    { code: "MM", label: "Myanmar", phone: "95" },
    { code: "MN", label: "Mongolia", phone: "976" },
    { code: "MO", label: "Macao", phone: "853" },
    {
      code: "MP",
      label: "Northern Mariana Islands",
      phone: "1-670",
    },
    { code: "MQ", label: "Martinique", phone: "596" },
    { code: "MR", label: "Mauritania", phone: "222" },
    { code: "MS", label: "Montserrat", phone: "1-664" },
    { code: "MT", label: "Malta", phone: "356" },
    { code: "MU", label: "Mauritius", phone: "230" },
    { code: "MV", label: "Maldives", phone: "960" },
    { code: "MW", label: "Malawi", phone: "265" },
    { code: "MX", label: "Mexico", phone: "52" },
    { code: "MY", label: "Malaysia", phone: "60" },
    { code: "MZ", label: "Mozambique", phone: "258" },
    { code: "NA", label: "Namibia", phone: "264" },
    { code: "NC", label: "New Caledonia", phone: "687" },
    { code: "NE", label: "Niger", phone: "227" },
    { code: "NF", label: "Norfolk Island", phone: "672" },
    { code: "NG", label: "Nigeria", phone: "234" },
    { code: "NI", label: "Nicaragua", phone: "505" },
    { code: "NL", label: "Netherlands", phone: "31" },
    { code: "NO", label: "Norway", phone: "47" },
    { code: "NP", label: "Nepal", phone: "977" },
    { code: "NR", label: "Nauru", phone: "674" },
    { code: "NU", label: "Niue", phone: "683" },
    { code: "NZ", label: "New Zealand", phone: "64" },
    { code: "OM", label: "Oman", phone: "968" },
    { code: "PA", label: "Panama", phone: "507" },
    { code: "PE", label: "Peru", phone: "51" },
    { code: "PF", label: "French Polynesia", phone: "689" },
    { code: "PG", label: "Papua New Guinea", phone: "675" },
    { code: "PH", label: "Philippines", phone: "63" },
    { code: "PK", label: "Pakistan", phone: "92" },
    { code: "PL", label: "Poland", phone: "48" },
    {
      code: "PM",
      label: "Saint Pierre and Miquelon",
      phone: "508",
    },
    { code: "PN", label: "Pitcairn", phone: "870" },
    { code: "PR", label: "Puerto Rico", phone: "1" },
    {
      code: "PS",
      label: "Palestine, State of",
      phone: "970",
    },
    { code: "PT", label: "Portugal", phone: "351" },
    { code: "PW", label: "Palau", phone: "680" },
    { code: "PY", label: "Paraguay", phone: "595" },
    { code: "QA", label: "Qatar", phone: "974" },
    { code: "RE", label: "Reunion", phone: "262" },
    { code: "RO", label: "Romania", phone: "40" },
    { code: "RS", label: "Serbia", phone: "381" },
    { code: "RU", label: "Russian Federation", phone: "7" },
    { code: "RW", label: "Rwanda", phone: "250" },
    { code: "SA", label: "Saudi Arabia", phone: "966" },
    { code: "SB", label: "Solomon Islands", phone: "677" },
    { code: "SC", label: "Seychelles", phone: "248" },
    { code: "SD", label: "Sudan", phone: "249" },
    { code: "SE", label: "Sweden", phone: "46" },
    { code: "SG", label: "Singapore", phone: "65" },
    { code: "SH", label: "Saint Helena", phone: "290" },
    { code: "SI", label: "Slovenia", phone: "386" },
    {
      code: "SJ",
      label: "Svalbard and Jan Mayen",
      phone: "47",
    },
    { code: "SK", label: "Slovakia", phone: "421" },
    { code: "SL", label: "Sierra Leone", phone: "232" },
    { code: "SM", label: "San Marino", phone: "378" },
    { code: "SN", label: "Senegal", phone: "221" },
    { code: "SO", label: "Somalia", phone: "252" },
    { code: "SR", label: "Suriname", phone: "597" },
    { code: "SS", label: "South Sudan", phone: "211" },
    {
      code: "ST",
      label: "Sao Tome and Principe",
      phone: "239",
    },
    { code: "SV", label: "El Salvador", phone: "503" },
    {
      code: "SX",
      label: "Sint Maarten (Dutch part)",
      phone: "1-721",
    },
    {
      code: "SY",
      label: "Syrian Arab Republic",
      phone: "963",
    },
    { code: "SZ", label: "Swaziland", phone: "268" },
    {
      code: "TC",
      label: "Turks and Caicos Islands",
      phone: "1-649",
    },
    { code: "TD", label: "Chad", phone: "235" },
    {
      code: "TF",
      label: "French Southern Territories",
      phone: "262",
    },
    { code: "TG", label: "Togo", phone: "228" },
    { code: "TH", label: "Thailand", phone: "66" },
    { code: "TJ", label: "Tajikistan", phone: "992" },
    { code: "TK", label: "Tokelau", phone: "690" },
    { code: "TL", label: "Timor-Leste", phone: "670" },
    { code: "TM", label: "Turkmenistan", phone: "993" },
    { code: "TN", label: "Tunisia", phone: "216" },
    { code: "TO", label: "Tonga", phone: "676" },
    { code: "TR", label: "Turkey", phone: "90" },
    {
      code: "TT",
      label: "Trinidad and Tobago",
      phone: "1-868",
    },
    { code: "TV", label: "Tuvalu", phone: "688" },
    {
      code: "TW",
      label: "Taiwan, Republic of China",
      phone: "886",
    },
    {
      code: "TZ",
      label: "United Republic of Tanzania",
      phone: "255",
    },
    { code: "UA", label: "Ukraine", phone: "380" },
    { code: "UG", label: "Uganda", phone: "256" },
    {
      code: "US",
      label: "United States",
      phone: "1",
      suggested: true,
    },
    { code: "UY", label: "Uruguay", phone: "598" },
    { code: "UZ", label: "Uzbekistan", phone: "998" },
    {
      code: "VA",
      label: "Holy See (Vatican City State)",
      phone: "379",
    },
    {
      code: "VC",
      label: "Saint Vincent and the Grenadines",
      phone: "1-784",
    },
    { code: "VE", label: "Venezuela", phone: "58" },
    {
      code: "VG",
      label: "British Virgin Islands",
      phone: "1-284",
    },
    {
      code: "VI",
      label: "US Virgin Islands",
      phone: "1-340",
    },
    { code: "VN", label: "Vietnam", phone: "84" },
    { code: "VU", label: "Vanuatu", phone: "678" },
    { code: "WF", label: "Wallis and Futuna", phone: "681" },
    { code: "WS", label: "Samoa", phone: "685" },
    { code: "XK", label: "Kosovo", phone: "383" },
    { code: "YE", label: "Yemen", phone: "967" },
    { code: "YT", label: "Mayotte", phone: "262" },
    { code: "ZA", label: "South Africa", phone: "27" },
    { code: "ZM", label: "Zambia", phone: "260" },
    { code: "ZW", label: "Zimbabwe", phone: "263" },
  ];

  const states = [
    { label: "Alabama" },
    { label: "Alaska" },
    { label: "Arizona" },
    { label: "Arkansas" },
    { label: "California" },
    { label: "Colorado" },
    { label: "Connecticut" },
    { label: "Delaware" },
    { label: "Florida" },
    { label: "Georgia" },
    { label: "Hawaii" },
    { label: "Idaho" },
    { label: "Illinois" },
    { label: "Indiana" },
    { label: "Iowa" },
    { label: "Kansas" },
    { label: "Kentucky" },
    { label: "Louisiana" },
    { label: "Maine" },
    { label: "Maryland" },
    { label: "Massachusetts" },
    { label: "Michigan" },
    { label: "Minnesota" },
    { label: "Mississippi" },
    { label: "Missouri" },
    { label: "Montana" },
    { label: "Nebraska" },
    { label: "Nevada" },
    { label: "New Hampshire" },
    { label: "New Jersey" },
    { label: "New Mexico" },
    { label: "New York" },
    { label: "North Carolina" },
    { label: "North Dakota" },
    { label: "Ohio" },
    { label: "Oklahoma" },
    { label: "Oregon" },
    { label: "Pennsylvania" },
    { label: "Rhode Island" },
    { label: "South Carolina" },
    { label: "South Dakota" },
    { label: "Tennessee" },
    { label: "Texas" },
    { label: "Utah" },
    { label: "Vermont" },
    { label: "Virginia" },
    { label: "Washington" },
    { label: "West Virginia" },
    { label: "Wisconsin" },
    { label: "Wyoming" },
  ];

  return (
    <div className="updateCasesContainer fadePanel">
      <Grid container xs={10} className="marginCenter">
  
       <Grid item xs={12}>
        {processMessage === true ? (
          <Typography className="messageBubble bottom floating">
            Case number <b style={{ color: "#e6ff6c" }}>{id}</b> has been
            updated successfully
          </Typography>
        ) : (
          ""
        )}
        </Grid>


        <Grid item xs={4}>
          <Typography
            fullWidth
            variant="body1"
            component="div"
            color={"#006bb5"}
            fontWeight="bold"
          >
            FOIA Case:
          </Typography>
          <div class="css-typing">
                <p>{caseDetailData.casenumber}</p> 
             </div>
       
        </Grid>
      
        <Grid item xs={4} />
           
        <Grid item xs={4} >
            <span style={{float: 'right'}} >
              <Typography
                  fullWidth
                  variant="body1"
                  component="div"
                  color={"#006bb5"}
                  fontWeight="bold"
                >
                  Case Status:
                </Typography>
              <StatusIndicator status={caseDetailData.status}  /> 
            </span>
        </Grid>

        <Grid container style={{border: '1px solid #b9c6d0', borderRadius: '10px', width: '100%', padding: '25px', paddingBottom: '50px'}} >
            <Grid item xs={2}>
              <Typography
                fullWidth
                variant="body1"
                component="div"
                color={"#006bb5"}
                fontWeight="bold"
                style={{ marginTop: "15px" }}
              >
                Last Name:
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                className="inputBox"
                variant={"outlined"}
                placeholder={caseDetailData.lastname  !== undefined  ? caseDetailData.lastname : 'Last Name'}
                name="lastName"
                onChange={(e) => e.target.value}
                {...register("lastName")}
              />
            </Grid>

            <Grid item xs={2}>
              <Typography
                fullWidth
                variant="body1"
                component="div"
                fontWeight="bold"
                style={{
                  marginTop: "15px",
                  marginLeft: "15px",
                  color: '#006bb5',
                  paddingLeft: '50px'
                }}
              >
                First Name:
              </Typography>
            </Grid>
            <Grid item xs={4}>
          

              <TextField
                fullWidth
                className="inputBox"
                variant={"outlined"}
                placeholder={caseDetailData.firstname  !== undefined  ? caseDetailData.firstname : 'First Name'}
                name="firstName"
                onChange={(e) => e.target.value}
                {...register("firstName")}
              />
            </Grid>

            <div className="spacer" />
            <div className="spacer" />

            <Grid item xs={2}>
              <Typography
                fullWidth
                variant="body1"
                component="div"
                color={"#006bb5"}
                fontWeight="bold"
                style={{ marginTop: "15px" }}
              >
                Address:
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                className="inputBox"
                variant={"outlined"}
                placeholder={caseDetailData.address  !== undefined  ? caseDetailData.address : 'Address'}
                name="address"
                onChange={(e) => e.target.value}
                {...register("address")}
              />
            </Grid>

            <div className="spacer" />
            <div className="spacer" />

            <Grid item xs={2}>
              <Typography
                fullWidth
                variant="body1"
                component="div"
                color={"#006bb5"}
                fontWeight="bold"
                style={{ marginTop: "15px" }}
              >
                City:
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                className="inputBox"
                variant={"outlined"}
                placeholder={caseDetailData.city  !== undefined  ? caseDetailData.city : 'City'}
                name="city"
                onChange={(e) => e.target.value}
                {...register("city")}
              />
            </Grid>

            <Grid item xs={2}>
              <Typography
                fullWidth
                variant="body1"
                component="div"
                color={"#006bb5"}
                fontWeight="bold"
                style={{
                  marginTop: "15px",
                  marginLeft: "50px",
                  paddingLeft: '50px'
                }}
              >
                State:
              </Typography>
            </Grid>
            <Grid item xs={4}>

              <Autocomplete
                options={states}
                autoHighlight
                getOptionLabel={(option) => option.label}
              
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    {option.label}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    className="inputBox"
                    {...params}
                    placeholder={caseDetailData.state  !== undefined  ? caseDetailData.state : 'State'}
                    name="state"
                    onChange={(e) => e.target.value}
                    {...register("state")}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", 
                    }}
                  />
                )}
              />
            </Grid>

            <div className="spacer" />
            <div className="spacer" />

            <Grid item xs={2}>
              <Typography
                fullWidth
                variant="body1"
                component="div"
                color={"#006bb5"}
                fontWeight="bold"
                style={{
                  marginTop: "15px"
                }}
              >
                ZIP:
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                className="inputBox"
                variant={"outlined"}
                placeholder={caseDetailData.zip  !== undefined  ? caseDetailData.zip : 'Zip'}
                name="zip"
                onChange={(e) => e.target.value}
                {...register("zip")}
              />
            </Grid>

            <Grid item xs={2}>
              <Typography
                fullWidth
                variant="body1"
                component="div"
                color={"#006bb5"}
                fontWeight="bold"
                style={{
                  marginTop: "15px",
                  marginLeft: "30px",
                  paddingLeft: '50px'
                }}
              >
                Country:
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
           
                id="country-select-demo"
                options={countries}
                autoHighlight
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                      alt=""
                    />
                    {option.label} ({option.code}) +{option.phone}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    className="inputBox"
                    {...params}
                    placeholder={caseDetailData.country  !== undefined  ? caseDetailData.country : 'Country'}
                    name="country"
                    onChange={(e) => e.target.value}
                    {...register("country")}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", 
                    }}
                  />
                )}
              />
            </Grid>

            <div className="spacer" />
            <div className="spacer" />

            <Grid item xs={2}>
              <Typography
                fullWidth
                variant="body1"
                component="div"
                color={"#006bb5"}
                fontWeight="bold"
                style={{ marginTop: "15px" }}
              >
                Email:
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                className="inputBox"
                variant={"outlined"}
                placeholder={caseDetailData.email  !== undefined  ? caseDetailData.email : 'Email'}
                name="email"
                onChange={(e) => e.target.value}
                {...register("email")}
              />
            </Grid>

            <Grid item xs={2}>
              <Typography
                fullWidth
                variant="body1"
                component="div"
                color={"#006bb5"}
                fontWeight="bold"
                style={{
                  marginTop: "15px",
                  marginLeft: "30px",
                  paddingLeft: '50px'
                }}
              >
                Phone:
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                variant={"outlined"}
                placeholder={caseDetailData.phone  !== undefined  ? caseDetailData.phone : 'Phone'}
                className="inputBox"
                name="phone"
                onChange={(e) => e.target.value}
                {...register("phone")}
              />
            </Grid>
        </Grid>

        <div className="spacer" />

        <Grid item xs={12} >
          <span style={{float: 'right'}}>
          <Button
            variant="contained"
            size="large"
            style={{
              margin: "0 auto",
              display: "flex",
              justifyContent: "center",
              color: "#006bb5",
              backgroundColor: "#bcd63e",
              fontWeight: "bold",
              width: "250px",
            }}
            onClick={handleSubmit(onSubmit)}
          >
            <FILE_ICON />
            &nbsp; SAVE
          </Button>
          </span>
        </Grid>

        <div className="spacer" />
        <div className="spacer" />
        <div className="dividerShape2" />
        <div className="spacer" />
        <div className="spacer" />

        <Grid item xs={3}>
          <Typography
            variant="body1"
            component="div"
            color={"#006bb5"}
            fontWeight="bold"
            fullWidth
            style={{ marginTop: "15px" }}
          >
            FOIA Request
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <TextField
            id="outlined-multiline-static"
            disabled
            multiline
            fullWidth
            variant={"outlined"}
            className="inputBox"
            value={caseDetailData.foiarequest}
            placeholder={caseDetailData.foiarequest}
            name="foiaRequest"

            inputProps={{
              style: {
                height: "150px",
                overflow: "auto",
              },
            }}
          />
        </Grid>

        <div className="spacer" />
        <div className="spacer" />

        <Grid item xs={3}>
          <Typography
            variant="body1"
            component="div"
            color={"#006bb5"}
            fontWeight="bold"
            fullWidth
            style={{ marginTop: "15px" }}
          >
            Assign to User:
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            variant={"outlined"}
            disabled
            value={caseDetailData.assigneduser}
            placeholder={caseDetailData.assigneduser}
            name="assignedUser"
            className="inputBox"
         
          />
        </Grid>
        <div className="spacer" />
        <div className="spacer" />
        <Grid item xs={3}>
          <Typography
            variant="body1"
            color={"#006bb5"}
            fontWeight="bold"
            fullWidth
            style={{ marginTop: "15px" }}
          >
            Date Received:
          </Typography>
        </Grid>
        <Grid item xs={4}>

          <TextField
            fullWidth
            variant={"outlined"}
            disabled
            placeholder={caseDetailData.receivedon !== undefined 
              ? format(new Date(caseDetailData.receivedon), "MM-dd-yyyy")
              : ""}
            className="inputBox"
          />


        </Grid>

      
        <div className="spacer" />
        <div className="spacer" />

        <Grid item xs={3}>
          <Typography
            fullWidth
            variant="body1"
            component="div"
            color={"#006bb5"}
            fontWeight="bold"
          >
            Associated Entities:
          </Typography>
        </Grid>

        <Grid item xs={9}>
          {caseDetailData.associatedentities === undefined
            ? "Associated Entities"
            : caseDetailData.associatedentities.map((item) => {
                return (
                  <Stack direction="row" spacing={2} style={{ padding: "5px" }}>
                    <Chip label={item.Entity} onDelete={(e) => e} />
                  </Stack>
                );
              })}
        </Grid>

        <div className="spacer" />
        <div className="spacer" />

        <Grid item xs={3}>
          <Typography
            fullWidth
            variant="body1"
            component="div"
            color={"#006bb5"}
            fontWeight="bold"
          >
            Case Files
            
             {
              caseDetailData.casefiles === undefined ? '' :  <span>&nbsp;&nbsp;({caseDetailData.casefiles.length})</span>
             }
          </Typography>
        </Grid>

        <Grid item xs={12}  style={{height: '600px', overflow: 'scroll'}} >
          <Typography
            style={{
              background: "#f2f6f9",
              padding: "25px",
              borderRadius: "10px",
            }}
          >
             <CaseDashboardTable 
                  caseNumber={caseDetailData.casenumber}
                  files={caseDetailData.casefiles}
             />
                   
            
          </Typography>
        </Grid>

        <div className="spacer" />
        <div className="spacer" />

        <Grid item xs={12}>
          {processingCase === true ? (
            <div class="loaderRing">
              UPDATING
              <span></span>
            </div>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Paper,
  TableContainer,
  Button,
  TablePagination,
  Chip,
  Tooltip,
  ListItem,
  List,
  Stack,
  Box,
  Divider,
  Typography
} from "@mui/material"
import axios from "axios"
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import { getCaseDetails } from "../service/services";
import { styled } from '@mui/material/styles'
import StatusIndicator from "./statusIndicator"
import MUIDataTable, { TableFilterList } from "mui-datatables"
import {
  DELETE_ICON,
  FILE_ICON
} from "../assets/icons/IconList"
import { format } from 'date-fns'

const Item = styled(Box)(({ theme }) => ({
  backgroundColor: "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.primary,
}));

function EditableTable(props) {
  return (
    <Grid container xs={12} spacing="12">
      <Grid item xs={8}>
        <TableContainer component={Paper} className="editableTable">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left">Case Number</TableCell>
                <TableCell align="left">Received</TableCell>
                <TableCell align="left">Bucket</TableCell>
                <TableCell align="left">Entities</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.data.map((cases) => {
                return (
                  <TableRow>
                    <TableCell sx={{ minWidth: 100 }}>
                      <Button>{cases.casenumber}</Button>
                    </TableCell>

                    <TableCell sx={{ minWidth: 100 }}>
                      {cases.receivedon}
                    </TableCell>

                    <TableCell sx={{ minWidth: 100 }}>{cases.s3path}</TableCell>

                    <TableCell>
                      {cases.associatedentities.map((entities) => {
                        return (
                          <span style={{ color: "#" }}>{entities} , </span>
                        );
                      })}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

/* Readable Table */

function ReadableTable(props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(7);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <div>
      <TableContainer component={Paper} className="editableTable">
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Details</TableCell>
              <TableCell>Case Number</TableCell>
              <TableCell>Assign User</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Received</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((cases) => (
                <StyledTableRow>
                  <TableCell sx={{ minWidth: 75 }}>
                    {/* <b style={{color: '#016bb5'}}>{cases.casenumber}</b> */}
                  </TableCell>

                  <TableCell sx={{ minWidth: 100 }}>{cases.status}</TableCell>

                  <TableCell sx={{ minWidth: 50 }}>
                    <StatusIndicator status={cases.status} />
                  </TableCell>

                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={props.data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}

function CaseDashboardTable(props) {
  
 const [processingDeleteFile, setProcessingDeleteFile] = React.useState(false)


  const handleDelete = async (file) =>{
    setProcessingDeleteFile(true)
    setTimeout(() => {
      setProcessingDeleteFile(false)
    }, 2000);

    await axios
      .post(`https://centcomservices.ais.saicds.com:8085/removefilebycase/`, {
        casenumber: props.caseNumber,
        file: file,
      })
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="fadePanel">
      {
            processingDeleteFile === true ? <div class="loaderRing2">
            DELETING FILE
            <span></span>
          </div>: ''
      }

      {props.files === undefined ? (
        <span>No case files</span>
      ) : (
        props.files.map((item) => {
          return (
            <span>
              <List
                sx={{
                  borderRadius: "12px",
                  boxShadow: "sm",
                  bgcolor: "transparent",
                  border: "1px solid #E1E1E1",
                  padding: "5px",
                  width: "100%",
                  margin: "0 auto",
                }}
              >
                <ListItem>
                  <Stack
                    direction="row"
                    style={{ width: "100%" }}
                    justifyContent="center"
                    divider={<Divider orientation="vertical" />}
                  >
                    <Item style={{ marginTop: "10px", width: '10%' }}>
                      <Typography>
                        <FILE_ICON iconColor={"#016bb5"} iconSize={"25px"} />
                      </Typography>
                    </Item>

                    <Item style={{ marginTop: "15px", width: '70%' }}>
                      <a
                        href={
                          "https://centcomservices.ais.saicds.com:8080" + item
                        }
                        target="_blank"
                      >
                        {item}
                      </a>
                    </Item>

                    <Item sx={{ width: '5%'}} >
                      <Divider orientation="vertical" />
                    </Item>

                    <Item sx={{ color: "#e73b3b", width: '15%', textAlign: 'right'}}>

                      <Button sx={{ color: "#e73b3b", textAlign: "center" }} onClick={(e) => handleDelete(item)}>
                        Delete
                        <DELETE_ICON iconColor={"#e73b3b"} iconSize={"25px"} />
                      </Button>
                    </Item>
                  </Stack>
                </ListItem>
              </List>
              <div className="spacer" />
            </span>
          );
        })
      )}
    </div>
  );
}

function SearchTable(props) {
  const [state, setState] = React.useState({
    selectedRowId: "",
  });

  const onRowsSelect = (currentRowsSelected, allRowsSelected) => {
    setState({
      selectedRowId: currentRowsSelected[0].index,
    });
    console.log(currentRowsSelected);
  };

  const handleDelete = () => (async) => {
    // Delete row

    return false;
  };

  const options = {
    rowStyle: { background: "#f5f5f5" },
    onRowsDelete: handleDelete(),
    selectableRows: true,
    selectableRowsOnClick: true,
    onRowsSelect: onRowsSelect,
  };

  const columns = [
    {
      name: "casenumber",
      label: "Case Dashboard",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Button
              href={"/cases/" + value}
              variant="outlined"
              className="gradient"
            >
              <Tooltip title={"Update case  " + value} placement="left" arrow>
                <Button>Case Dashboard</Button>
              </Tooltip>
            </Button>
          );
        },
      },
    },
    { name: "casenumber", label: "Case Number", options: { filter: true } },
    {
      name: "assigneduser",
      label: "Assigned User",
      options: { filter: true, sort: true },
    },

    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <StatusIndicator status={value} />;
        },
      },
    },
    {
      name: "receivedon",
      label: "Received On",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
         
          return value !== undefined 
            ? format(new Date(value), "MM-dd-yyyy")
            : "";
        },
      },
    },
    {
      name: "trackingcomments",
      label: "Tracking Comments",
      options: { filter: true },
    },
  ];

  const CustomChip = ({ label, onDelete }) => {
    return (
      <Chip
        variant="outlined"
        color="primary"
        label={label}
        onDelete={onDelete}
      />
    );
  };

  const CustomFilterList = (props) => {
    return <TableFilterList {...props} ItemComponent={CustomChip} />;
  };

  return (
    <div className="fadePanel">
      {console.log(props.data)}

      <MUIDataTable
        title={<Typography sx={{color: '#016bb5'}}>Existing Cases</Typography>}
        data={props.data}
        columns={columns}
        options={options}
        components={{
          TableFilterList: CustomFilterList,
        }}
      />
    </div>
  );
}

export { EditableTable, ReadableTable, SearchTable, CaseDashboardTable };

import React from "react"
import { Grid, Typography } from "@mui/material/"
import Cases from "./content/cases/index"
import { useParams } from "react-router-dom"

export default function Case() {

  const { id } = useParams()

  return (
    <Grid
      container
      xs={12}
      sm={12}
      md={12}
      lg={12}
      spacing={3}
      className="caseDetailsContainer"
    >
    
      <Grid item xs={12} className="marginLeft">
        <Cases />
      </Grid>
    </Grid>
    
  )
}

import React, { useState, useEffect } from "react";
import { Grid, Paper, Typography, Button } from "@mui/material/";
import { PageTitle } from "../components/pageTitle";
import { getFoiaCaseData } from "../service/services";
import { ReadableTable } from "../components/table";
import { useForm } from "react-hook-form";
import { SEARCH_ICON, EDIT_ICON, LOCK_ICON } from "../assets/icons/IconList";
import CENTCOM from "../assets/images/CENTCOM.png";
import CENTRAL_COMMAND_SEAL from "../assets/images/CentralCommandSeal.png";

export default function Dashboard(props) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    retrieveData();
  }, []);

  const [state, setState] = useState({
    cases: [],
  });

  const [caseSelected, setSelectedCase] = React.useState("");

  // Service Calls
  const retrieveData = () => {
    getFoiaCaseData()
      .then((response) => {
        setState({
          cases: response.data.cases,
        });
        console.log(response.data.cases);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div className="dashboardContainer">
      {/* Banner  */}
      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className="dashboardBanner"
      >
        <Grid item xs={4} />
        <Grid item xs={4}>



          <Typography className="introMessage">
            <Typography>
              <img
                src={CENTCOM}
                style={{
                  width: "175px",
                  textAlign: "center",
                  paddingBottom: "15px",
                }}
                alt="CENTCOM"
              />
            </Typography>
            <div className="dividerShape" />
            <div className="spacer" />
            <i>USCentcom</i> directs and enables military operations and
            activities with allies and partners to increase regional security
            and stability in support of enduring U.S. interests.
          </Typography>
        </Grid>

        <Grid item xs={3}  className="seal" >
          <img src={CENTRAL_COMMAND_SEAL} style={{width: '150px'}}   alt="Central command seal" />
 
     
        </Grid>
      </Grid>

      <div className="spacer" />

      {/* Tile Options */}
      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className="tiles"
        spacing={4}
      >
        {/* <Grid item lg={6} /> */}
        <Grid item xs={6}  className="createCase">
          <Paper elevation={5}>
            <Typography variant="body2" className="centerAlign editIcon">
              <EDIT_ICON iconSize={"45px"} iconColor={"#BCD63E"} />
            </Typography>

            <Typography variant="h4" className="centerAlign">
              Create Case
            </Typography>

            <Typography variant="body1" style={{ marginTop: "5px" }}>
              Create new FOIA case
            </Typography>

            <Typography>
              <Button
                href="/foia"
                variant="contained"
                style={{ color: "#fff" }}
              >
                CREATE
              </Button>
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={6}  className="caseSearch">
          <Paper elevation={5}>
            <Typography variant="body2" className="centerAlign">
              <SEARCH_ICON
                iconSize={"45px"}
                iconColor={"#016bb5"}
                className="searchIcon"
              />
            </Typography>

            <Typography variant="h4" className="centerAlign">
              Existing Case
            </Typography>

            <Typography variant="body1" style={{ marginTop: "5px" }}>
              View all existing cases
            </Typography>

            <Typography>
              <Button
                variant="contained"
                href="/casedetails"
                style={{ color: "#fff" }}
              >
                VIEW
              </Button>
            </Typography>
          </Paper>
        </Grid>
 
      </Grid>
      <div className="spacer" />
    </div>
  )
}

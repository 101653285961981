import * as React from "react";
import {
  Box,
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Button,
  Typography,
  Avatar,
  IconButton,
  Collapse,
} from "@mui/material";
import LinesEllipsis from "react-lines-ellipsis";

import { styled } from "@mui/material/styles";
import { EDIT_ICON } from "../assets/icons/IconList";

import { red } from "@mui/material/colors";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CircularProgressWithLabel from "../components/progress";
import { format, intervalToDuration } from "date-fns";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

function BasicCard(props) {


  return (
    <Card
      sx={{
        maxWidth: 345,
        height: 330,
        marginTop: "-10px",
        marginLeft: "25px",
      }}
    >
      <CardHeader
        sx={{ textAlign: "center", color: "#016bb5" }}
        title={props.title}
        subheader={props.subTitle}
      />

      <CardContent>
        <Button
          href={props.type}
          variant="contained"
          onClick={(e) =>
            sessionStorage.setItem("CaseNumber", props.caseNumber)
          }
          style={{
            textAlign: "center",
            color: "#016bb5",
            width: "50%",
            fontSize: "12px",
            padding: "10px",
            background: "#bcd63e",
            marginLeft: "80px",
          }}
        >
          Select
        </Button>
        <div className="spacer" />
        {props.desc === true ? (
          <Typography variant="body2" color="text.secondary">
            Natural language searching capabilities refer to the ability of our
            powerful search engine to interpret and process human language input
            in a way that allows for more intuitive and accurate search results.
          </Typography>
        ) : (
          ""
        )}

        {props.desc2 === true ? (
          <Typography variant="body2" color="text.secondary">
            Tradition type searching involves using logical operators such as
            "AND," "OR," and "NOT" to create complex search queries that can be
            used to narrow or broaden search results.
          </Typography>
        ) : (
          ""
        )}
      </CardContent>
    </Card>
  );
}

function SearchCard(props) {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const selectCard = (event) => {
    console.log("Car Selected", event.target.checked);
    props.selected(event.target.checked);
  };

  const card = (
    <React.Fragment>
      <div class="card">
        <div class="content">
          <div class="front">
            <CardContent style={{ textAlign: "left" }}>
              <Typography
                sx={{ mb: 1.5 }}
                color="text.secondary"
                className="capitalize desc"
              >
                {/* Need Index to put in first args */}
                {/* <Checkbox 
                    onChange={(e) => isCheckboxChecked(props.id, e.target.checked)}
                    {...label} /> */}

                {/* <Checkbox 
                    onClick={selectCard }
                    {...label} /> */}
              </Typography>

              <Typography>
                <b>File Name:&nbsp; </b>
                <small className="file">{props.FileName}</small>
              </Typography>

              <Typography>
                <b>Context: </b>
              </Typography>

              <Typography color="text.secondary" style={{ float: "left" }}>
                {props.Context === "" ? (
                  "N/A"
                ) : (
                  <LinesEllipsis
                    text={props.Context}
                    maxLine="4"
                    ellipsis="..."
                    trimRight
                    basedOn="letters"
                  />
                )}

                <div className="spacer" />
              </Typography>

              <Typography>
                <b>Score:&nbsp; </b>
                <small className="file">{props.Score}</small>
              </Typography>
            </CardContent>
          </div>
        </div>
      </div>
    </React.Fragment>
  );

  return (
    <Box>
      <Card variant="outlined" className="cardComponent">
        {card}
      </Card>
    </Box>
  );
}

function QueryCard(props) {
  const bull = (
    <Box
      component="span"
      sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
    >
      •
    </Box>
  );

  const card = (
    <React.Fragment>
      <CardContent className="fadePanel">
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {props.name}
        </Typography>

        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {props.author}
        </Typography>

        <Typography variant="body2">
          <b>{props.organization}</b>
        </Typography>
        <div className="spacer" />
        <Typography variant="body2">
          <LinesEllipsis
            text={props.content}
            maxLine="6"
            ellipsis="..."
            trimRight
            basedOn="letters"
          />
        </Typography>
      </CardContent>
    </React.Fragment>
  );
  return (
    <Box sx={{ minWidth: 275 }} className="fadePanel">
      <Card variant="outlined" style={{ padding: "15px" }}>
        {card}
      </Card>
    </Box>
  );
}

function CaseDashboardCard(props) {
  return (
    <Card
      sx={{ height: 185 }}
      style={{
        background: props.background,
        color: props.color,
        borderRadius: "12px",
      }}
    >
      <CardContent>
        <Typography
          gutterBottom
          variant="body2"
          component="div"
          style={{ fontWeight: "600" }}
        >
          <Avatar style={{ float: "right" }}>{props.icon}</Avatar>
          {props.cardTitle}
        </Typography>

        <Typography
          variant="h4"
          style={{ fontWeight: "100", fontSize: "26px" }}
        >
          {props.date === true && props.title !== undefined
            ? format(new Date(props.title), "MM-dd-yyyy")
            : props.title}
           
        </Typography>
        {
          props.isStatus === true ? 
          <Typography
          variant="h4"
          style={{ fontWeight: "100", fontSize: "22px", marginTop: '10px' }}
        >
        
          <span style={{float: 'left'}}>
          Status: {props.status}
          </span>
        
        </Typography>
        :''
          }
        
        <Typography sx={{ margin: "25px auto", textAlign: "center" }}>
          {props.progress === true ? (
            <CircularProgressWithLabel receivedDate={props.receivedOn} />
          ) : (
            ""
          )}
        </Typography>

        <Typography>
          {props.edit === true ? (
            <EDIT_ICON iconSize={"18px"} iconColor={"white"} float={"right"} />
          ) : (
            ""
          )}
        </Typography>
      </CardContent>
    </Card>
  );
}

export { SearchCard, QueryCard, CaseDashboardCard, BasicCard };

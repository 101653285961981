import React from "react"
import { Grid, Typography } from "@mui/material"
import SubSAICLogo from "../assets/images/Saic-Logo-Blue.png"
import SAICSquareLogo from "../assets/images/SaicSquareBackground.png"

const Footer = () => {
  return (
    <div>
      <Grid container sx={12} className="footer">
        <Grid className="footerContainerLeft" item xs={4}>
          <img src={SubSAICLogo}  alt="Powered By SAIC" />
          
        </Grid>
        
        <Grid className="footerContainerRight" item xs={8}>
          <img src={SAICSquareLogo}  alt="Triangles" />
        </Grid>
      </Grid>
    </div>
  )
}

export default Footer

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
  Grid,
  Box,
  Stack,
  TextField,
  Button,
  Chip,
  Typography,
} from "@mui/material/";
import axios from "axios";
import { getCaseDetails } from "../../service/services";

import { useForm, Controller } from "react-hook-form";
import { getCaseFilesCount } from "../../utils/fileCount"
import { CaseFileResultsList } from "../../components/list"
import { format, addDays } from 'date-fns'
import {
  CASE_OFFICER,
  CASE_NUMBER,
  FILE_COUNT,
  DATE_RECEIVED,
  DAYS_TOCLOSE,
  DUE_DATE,
  ADD_ICON,
} from "../../assets/icons/IconList";
import { CaseDashboardCard, BasicCard } from "../../components/cards"
import StatusIndicator from "../../components/statusIndicator"

const BASE_URL = process.env.REACT_APP_BASE_URL;

const Item = styled(Box)(({ theme }) => ({
  backgroundColor: "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.primary,
}));

export default function CaseDetails(props) {

  const [entities, setEntities] = React.useState([]);
  const [caseDetailData, setCaseDetailData] = React.useState([]);
  const [showMessage, setShowMessage] = React.useState(false);
  const [processingFileKeywords, setProcessingFileKeywords] = React.useState(false);

  useEffect(() => {
    
    retrieveCaseDetails(id)

  }, [caseDetailData])

  // Service Call
  const retrieveCaseDetails = async (caseNumber) => {
    console.log("caseNum", caseNumber);

    await getCaseDetails(caseNumber)
      .then((response) => {
        console.log("Case Details: ", response.data.case);
        setCaseDetailData(response.data.case);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  // Handlers
  const handleAddFilesByKeyword = async () => {
    setProcessingFileKeywords(true);

    await axios
      .post(
        `https://centcomservices.ais.saicds.com:8000/entity-question-generation`,
        {
          entities: getEntities(),
        }
      )
      .then((response) => {
        console.log(response.data.results);

        updatePaths(response.data.results);

        setProcessingFileKeywords(false);
        setShowMessage(true);

        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updatePaths = (results) => {
    const pathArr = [];
    const newArr = Object.values(results);

    newArr.map((item) => {
      item.answers.map((item) => {
        pathArr.push(item.Meta.path);
      });
    });

    axios
      .post(`https://centcomservices.ais.saicds.com:8085/addfilesbycase/`, {
        casenumber: caseDetailData.casenumber,
        files: pathArr,
      })
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getEntities = () => {
    const arr = [];

    if (caseDetailData.associatedentities != undefined) {
      caseDetailData.associatedentities.map((entity) => {
        arr.push({ name: entity.Entity, label: entity.Label });
      });
      return arr;
    }
  }

  const calcDaysTillClose = (date) => {
    const MAX_DAYS = 23
    let receivedDay
    let daysLeft
    let curDate
    let totalDays
    let formatTotalDays 
    if(date !== undefined){
      receivedDay = parseInt(format(new Date(date), "dd"))
      totalDays = addDays(new Date(date), MAX_DAYS)
      formatTotalDays =  parseInt(format(new Date(totalDays), "dd"))

      curDate = parseInt(format(new Date(), "dd"))
      daysLeft = (formatTotalDays - receivedDay)
      if(formatTotalDays > receivedDay){
        daysLeft = (formatTotalDays - receivedDay)
      }
      if(formatTotalDays < receivedDay){
        daysLeft = (receivedDay - formatTotalDays)
      }

    
    }

    return JSON.stringify(daysLeft)
  }

  const calcDueDate = (date) => {
    let result
    const MAX_DAYS = 23

    if(date !== undefined){
       result = addDays(new Date(date), MAX_DAYS)
    }

     return result

  }


  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const { id } = useParams();

  return (
    <div className="caseDashboardContainer fadePanel">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container xs={12} spacing={1}>
          <Grid item xs={2}>
            <Item>
              <CaseDashboardCard
                cardTitle={"Case Officer"}
                title={caseDetailData.assigneduser}
                background={"#006BB5"}
                color={"#FFFFFF"}
                icon={<CASE_OFFICER />}
                isStatus={false}
                edit={false}
                date={false}
              />
            </Item>
          </Grid>

          <Grid item xs={2}>
            <Item>
              <CaseDashboardCard
                cardTitle={"Case Number"}
                title={caseDetailData.casenumber}
                background={"#006BB5"}
                color={"#FFFFFF"}
                icon={<CASE_NUMBER />}
                date={false}
                isStatus={true}
                status={<StatusIndicator status={caseDetailData.status}  />}
              />
            </Item>
          </Grid>

          <Grid item xs={2}>
            <div className="floating">
              {showMessage === true ? (
                <span className="filesAddedResultsBubble bottom">
                  New Case files have been added based on the key words
                </span>
              ) : (
                ""
              )}
            </div>

            <Item>
              <CaseDashboardCard
                cardTitle={"File Count"}
                title={
                  caseDetailData.casefiles === undefined
                    ? "0"
                    : getCaseFilesCount(caseDetailData.casefiles)
                }
                background={"#006BB5"}
                color={"#FFFFFF"}
                icon={<FILE_COUNT />}
                isStatus={false}
                date={false}
              />
            </Item>
          </Grid>

          <Grid item xs={2}>
            <Item>
              <CaseDashboardCard
                cardTitle={"Date Received"}
                title={caseDetailData.receivedon}
                background={"#E1E1E1"}
                color={"#1C1F25"}
                icon={<DATE_RECEIVED />}
                isStatus={false}
                date={true}
              />
            </Item>
          </Grid>
          <Grid item xs={2}>
            <Item>
              <CaseDashboardCard
                cardTitle={"Days till Close"}
                title={calcDaysTillClose(caseDetailData.receivedon)}
                background={"#E1E1E1"}
                color={"#1C1F25"}
                icon={<DAYS_TOCLOSE />}
                progress={true}
                isStatus={false}
                date={false}
                receivedOn={caseDetailData.receivedon}
              />
            </Item>
          </Grid>

          <Grid item xs={2}>
            <Item>
              <CaseDashboardCard
                cardTitle={"Due Date"}
                title={calcDueDate(caseDetailData.receivedon)}
                background={"#E1E1E1"}
                isStatus={false}
                icon={<DUE_DATE />}
                color={"#1C1F25"}
                date={true}
              />
            </Item>
          </Grid>

          {/* END Top Cards Section (6) */}

          <div className="spacer" />

          {/* Second Column Main Container Section */}
          <Grid container xs={12} style={{ padding: "5px" }}>
            <Grid container xs={6}>
              {/* Searching Section */}
              <Grid
                container
                xs={12}
                style={{
                  border: "1px solid #D9D9D9",
                  borderRadius: "12px",
                  padding: "20px",
                  marginTop: "8px",
                  marginLeft: "12px",
                  marginRight: "10px",
                  marginBottom: "10px",
                }}
              >
                <Grid item xs={12} >
                  <Typography
                    sx={{
                      color: "#016bb5",
                      fontSize: "20px",
                      marginBottom: "15px",
                    }}
                  >
                    Add Files to Case
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <BasicCard
                    title={"Natural Language Search"}
                    subTitle={"Human-like Search Interpretation"}
                    caseNumber={caseDetailData.casenumber}
                    type={"/search"}
                    desc={true}
                  />
                </Grid>

                <Grid item xs={6}>
                  <BasicCard
                    title={"Traditional Type Search"}
                    subTitle={"Query & Filter Case Files"}
                    caseNumber={caseDetailData.casenumber}
                    type={"/queryBuilder"}
                    desc2={true}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid container xs={6} sx={{ padding: "10px" }}>
              <Grid
                item
                xs={12}
                style={{
                  background: "#016bb5",
                  borderRadius: "12px",
                  marginLeft: "10px",
                  marginRight: "-10px",
                }}
              >
                <Stack>
                  <Item>
                    <Typography sx={{ color: "#ffffff", paddingLeft: "10px" }}>
                      FOIA Request
                    </Typography>
                  </Item>
                  <Item>
                    <Typography
                      variant="body1"
                      style={{
                        background: "#fff",
                        padding: "15px",
                        width: "90%",
                        margin: "0 auto",
                        borderRadius: "8px",
                      }}
                    >
                      {caseDetailData.foiarequest}
                    </Typography>
                  </Item>
                  <Item>
                    <div className="spacer" />
                  </Item>
                </Stack>
              </Grid>
              <div className="spacer" />

              <Grid
                item
                xs={12}
                style={{
                  background: "#e5ebee",
                  borderRadius: "12px",
                  marginLeft: "12px",
                  marginRight: "-10px",
                }}
              >
                <Stack>
                  <Item>
                    <Typography
                      sx={{
                        color: "#016bb5",
                        paddingLeft: "10px",
                        fontSize: '20px',
                        paddingTop: '10px'
                      }}
                    >
                      Key Words/Phrases
                    </Typography>
                  </Item>

                  <Item style={{ height: "100px", paddingLeft: "25px" }}>
                    {caseDetailData.associatedentities === undefined
                      ? ""
                      : caseDetailData.associatedentities.map((entity) => {
                          return <Chip label={entity.Entity} />;
                        })}
                  </Item>

                  <Item>
                    {processingFileKeywords === true ? (
                      <div class="loadingContainer">
                        <div class="loading">
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    <Button
                      onClick={handleAddFilesByKeyword}
                      sx={{
                        color: "#016bb5",
                        background: "#bcd63e",
                        float: "right",
                        padding: "8px",
                        marginRight: "25px",
                      }}
                    >
                      <ADD_ICON iconSize={"15px"} iconColor={"#016bb5"} />{" "}
                      &nbsp;Add To Files
                    </Button>
                  </Item>
                  <div className="spacer" />
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <div className="spacer" />
        </Grid>
        {/* END Second Column Main Container Section */}

        <div className="spacer" />

        {/* Third Column Container Section */}
        <Grid container xs={12} sx={{ margin: "0 auto" }}>
          {/* Case Files Section */}
          <Grid item xs={12}>
            <Typography sx={{ color: "#016bb5", fontSize: "20px" }}>
              Case Files (
              {caseDetailData.casefiles === undefined
                ? ""
                : caseDetailData.casefiles.length}
              )
            </Typography>
            <div className="spacer" />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              height: "400px",
              overflow: "auto",
              border: "1px solid #D9D9D9",
              borderRadius: "12px",
              padding: "5px",
              marginLeft: "10px",
              marginRight: "10px",
              background: '#fff'
            }}
          >
            <CaseFileResultsList files={caseDetailData.casefiles} />

          </Grid>
        </Grid>
      </Box>
    </div>
  );
}


import React from "react"


 function getCaseFilesCount(files){

    let fileCount = files.length
     return  fileCount

  }


export {
    getCaseFilesCount
}
import React, { useState, useEffect, useReducer } from "react";
import { useTheme } from "@mui/material/styles";
import {
  TextField,
  Typography,
  Box,
  MenuItem,
  FormControlLabel,
  Select,
  ListItem,
  Chip,
  Grid,
  Button,
} from "@mui/material/";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { getFoiaCaseData } from "../service/services";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import { ADD_ICON } from "../assets/icons/IconList";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const requestedBy = ["Justin Jackson", "Chad Stauffer", "Tim Bassett"];
const assignedUser = [
  "Justin Jackson",
  "Chad Stauffer",
  "Tim Bassett",
  "Garret DeChellis",
];

function getStyles(name, entityName, theme) {
  return {
    fontWeight:
      entityName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function FOIACasePage(props) {
  useEffect(() => {
    retrieveData();
  }, []);

  const [state, setState] = useState({
    cases: [],
    isSingleWord: false,
    whichSelectList: "entityType",
  });
  const theme = useTheme();

  const [caseSelected, setSelectedCase] = React.useState("");
  const [caseNumber, setCaseNumber] = React.useState("");
  const [foiaRequest, setFoiaRequest] = React.useState("");
  const [customEntities, setCustomEntities] = React.useState("");
  const [entities, setEntities] = React.useState([]);
  const [newEntities, setNewEntities] = React.useState([]);
  const [entityName, setEntityName] = React.useState([]);
  const [assignToUser, setAssignToUser] = React.useState("");
  const [requestedByUser, setRequestedByUser] = React.useState("");
  const [caseClosed, setCaseClosed] = React.useState(dayjs("mm-dd-yyyy"));
  const [dateReceived, setDateReceived] = React.useState(dayjs("2022-04-07"));
  const [nersName, setNersName] = React.useState([]);
  const [chipData, setChipData] = React.useState([""]);
  const [processingCase, setProcessingCase] = React.useState(false);
  const [loadingEntities, setLoadingEntities] = React.useState(false);
  const [processMessage, setProcessMessage] = React.useState(false)
  const [showTableCaseNav, setShowTableCaseNav] = React.useState(false)

  // Service Calls
  const retrieveData = () => {
    getFoiaCaseData()
      .then((response) => {
        setState({
          cases: response.data.cases,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const retrieveCustomEntities = (textData) => {
    setLoadingEntities(true);
    console.log(textData);
    axios
      .post(
        `https://centcomservices.ais.saicds.com:8088/api/v1/extract_single_text`,
        {
          text: textData,
        }
      )
      .then((response) => {
        console.log("Extract data", response.data);
        setLoadingEntities(false);
        setNewEntities(response.data.Entities);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const retrieveExtractedEntities = (textData) => {
    setLoadingEntities(true);
    console.log(textData);

    axios
      .post(
        `https://centcomservices.ais.saicds.com:8088/api/v1/extract_single_text`,
        {
          text: textData,
        }
      )
      .then((response) => {
        console.log("Extract data", response.data.Entities);
        setLoadingEntities(false);

        setEntities(response.data.Entities);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const initForm = {
    caseNumber: "",
    assignedUser: "",
    receivedOn: "",
    requestedBy: "",
  };

  const [formVal, dispatch] = useReducer(
    (curVal, newVal) => ({ ...curVal, ...newVal }),
    initForm
  );

  // Handlers

  const handleAssignedUser = (event) => {
    console.log("Assigne User", event.target.value);
    setAssignToUser(event.target.value);
  };

  const handleRequestedBy = (event) => {
    console.log("Requested By", event.target.value);
    setRequestedByUser(event.target.value);
  };

  const handleNERSChange = (event) => {
    console.log(event.target.value);
    setNersName(event.target.value);
  };

  const handleCustomChipDelete = (chipToDelete) => {
    console.info("Chip Deleted", chipToDelete);

    setNewEntities((chips) =>
      chips.filter((chip) => chip.Entity !== chipToDelete.Entity)
    );
  };

  const handleDelete = (chipToDelete) => () => {
    console.info("Chip Deleted", chipToDelete);
    setEntities((chips) =>
      chips.filter((chip) => chip.Entity !== chipToDelete.Entity)
    );
  };

  const handleFoiaRequest = (event) => {
    console.log(event.target.value);
    setFoiaRequest(event.target.value);
  };

  const handleCustomEntities = (event) => {
    setCustomEntities(event.target.value);
  };

  const handleCreateEntities = () => {
    retrieveCustomEntities(customEntities);
  };

  const handleExtractEntities = () => {
    console.log("Extract Entities", foiaRequest);

    retrieveExtractedEntities(foiaRequest);
  };

  // Submit New Foia case handler
  const onSubmit = (data) => {
    let customEntityFormat = [{ Label: "Custom", Entity: customEntities }];
    let entityformatted = entities.concat(customEntityFormat);

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    setProcessingCase(true);
    setCaseNumber(data.caseNumber);

    axios
      .post(`${BASE_URL}cases/`, {
        assigneduser: data.assignedUser,
        associatedentities: entityformatted,
        casefiles: [],
        foiarequest: foiaRequest,
        requestedby: data.requestedBy,
        casenumber: data.caseNumber,
        receivedon: dateReceived,
        caseclosed: '2022-03-29T05:00:000Z',
        status: "Open",
        trackingcomments: data.reasonForFOIA,
      })
      .then((response) => {
        console.log(response.data);
        setTimeout(() => {
          setProcessingCase(false)
          setProcessMessage(true)
          setShowTableCaseNav(true)
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="foiaCaseContainer">
      <Grid container xs={12}>
        <Grid container xs={12}>
          <Grid item xs={10}>
              {processMessage === true ? (
                <Typography className="messageBubble bottom floating">
                  <span style={{color: '#bcd63e'}} >New</span> FOIA Case has been created successfully
                </Typography>
              ) : (
                ""
              )}
          </Grid>
          <Grid item xs={2} >
            {
              showTableCaseNav === true ? <Button variant="outlined" href={"/casedetails"}>View All Cases</Button> : ''
            }
          </Grid>
          
          <div className="spacer" />
          <Grid item xs={2}>
            <Typography
              variant="body1"
              component="div"
              color={"#006bb5"}
              fontWeight="bold"
              fullWidth
              style={{ marginTop: "15px" }}
            >
              Assign to User:
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <FormControlLabel
              style={{ width: "100%", margin: "0" }}
              control={
                <Controller
                  control={control}
                  name="assignedUser"
                  defaultValue={
                    assignToUser === undefined ? "Assigned User" : assignToUser
                  }
                  rules={{ required: true }}
                  inputRef={register()}
                  render={({ field: { onChange } }) => (
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-multiple-name"
                      fullWidth
                      MenuProps={MenuProps}
                      className="inputBox"
                      error={!!errors?.assignedUser}
                      helperText={
                        errors?.assignedUser
                          ? errors.assignedUser.message
                          : null
                      }
                      onChange={(e) => onChange(e.target.value)}
                    >
                      {assignedUser.map((name) => (
                        <MenuItem
                          key={name}
                          value={name}
                          style={getStyles(name, assignToUser, theme)}
                        >
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              }
            />
          </Grid>
          <div className="spacer" />
          <div className="spacer" />
          <Grid item xs={2}>
            <Typography
              variant="body1"
              color={"#006bb5"}
              fontWeight="bold"
              fullWidth
              style={{ marginTop: "15px" }}
            >
              Date Received:
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={new Date() }
                name="receivedOn"
                onChange={(newValue) => {
                  setDateReceived(newValue);
                }}
                renderInput={(params) => <TextField fullWidth {...params} />}
                className="inputBox"
              />
            </LocalizationProvider>
          </Grid>
          <div className="spacer" />
          <div className="spacer" />
          
          <Grid item xs={2}>
            <Typography
              variant="body1"
              component="div"
              color={"#006bb5"}
              fontWeight="bold"
              fullWidth
              style={{ marginTop: "15px" }}
            >
              FOIA Request:
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <TextField
              id="outlined-multiline-static"
              multiline
              fullWidth
              name="foiaRequest"
              onChange={handleFoiaRequest}
              className="inputBox"
              inputProps={{
                style: {
                  height: "150px",
                  overflow: "auto",
                },
              }}
            />
          </Grid>
          &nbsp;&nbsp;
          <Grid item xs={12}>
            <div className="spacer" />
            <Button
              size="small"
              style={{ float: "right", padding: "8px" }}
              onClick={handleExtractEntities}
              variant="contained"
            >
              Extract Entities
            </Button>
          </Grid>
          <div className="spacer" />
          <div className="spacer" />
          <Grid item xs={2}>
            <Typography
              variant="body1"
              component="div"
              color={"#006bb5"}
              fontWeight="bold"
              fullWidth
              style={{ marginTop: "15px" }}
            >
              Custom Entities:
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <TextField
              id="outlined-multiline-static"
              fullWidth
              onChange={handleCustomEntities}
              name="customEntities"
              className="inputBox"
              inputProps={{
                style: {
                  height: "25px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <div className="spacer" />
            <Button
              size="small"
              style={{ float: "right", padding: "8px" }}
              onClick={handleCreateEntities}
              variant="contained"
            >
              Create Entities
            </Button>
          </Grid>
          <div className="spacer" />
          <div className="spacer" />
          <Grid item xs={2}>
            <Typography f variant="body1" color={"#006bb5"} fontWeight="bold">
              Entities:
            </Typography>
          </Grid>
          <Grid item xs={10}>
            {state.isSingleWord === true ? (
              <TextField
                className="inputBox"
                fullWidth
                id="outlined-basic"
                label="Please type a single word"
                variant="outlined"
              />
            ) : (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {entities.map((entity) => (
                  <Chip label={entity.Entity} onDelete={handleDelete(entity)} />
                ))}

                {newEntities.map((entity) => (
                  <Chip
                    label={entity.Entity}
                    onDelete={(e) => handleCustomChipDelete(entity)}
                  />
                ))}
              </Box>
            )}
          </Grid>
          <div className="spacer" />
          <div className="spacer" />
          <div className="spacer" />
          <div className="dividerShape2" />
          <div className="spacer" />
          <Grid item xs={12} style={{ margin: "0 auto", textAlign: "center" }}>
            <Button
              variant="contained"
              size="large"
              style={{
                margin: "0 auto",
                display: "flex",
                justifyContent: "center",
                color: "#006bb5",
                backgroundColor: "#bcd63e",
                fontWeight: "bold",
                padding: "15px",
              }}
              onClick={handleSubmit(onSubmit)}
            >
              <ADD_ICON /> &nbsp; Create FOIA Case
            </Button>
          </Grid>
          <Grid item xs={12}>
            {processingCase === true ? (
              <div class="loaderRing">
                CREATING
                <span></span>
              </div>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

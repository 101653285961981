import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { StyledEngineProvider } from "@mui/material/styles";
import Home from "./home";
import AppWrapper from "./template/appTemplate";
import "./assets/sheets/main.scss";
import Search from "./search";
import FOIA from "./FOIA";
import FOIACaseDetails from "./caseDetails";
import Redaction from "./redaction";
import QueryBuilder from "./queryBuilder";
import CaseSearch from "./FOIARequestCaseSearch";
import Cases from "./cases";

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <div className="appContainer">
        <AppWrapper>
          <BrowserRouter>
            <Routes>
              <Route path="/" exact element={<Home />} />
              <Route path="/search" exact element={<Search />} />
              <Route path="/foia" exact element={<FOIA />} />
              <Route path="/casedetails" exact element={<FOIACaseDetails />} />
              <Route path="/redaction" exact element={<Redaction />} />
              <Route path="/queryBuilder" exact element={<QueryBuilder />} />
              <Route path="/casesearch" exact element={<CaseSearch />} />
              <Route path="/cases/:id" exact element={<Cases />} />
            </Routes>
          </BrowserRouter>
        </AppWrapper>
      </div>
    </StyledEngineProvider>
  );
}

export default App;

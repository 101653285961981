import React, { useState, useEffect } from "react"
import { useTheme } from "@mui/material/styles"

import {
  Card,
  TextField,
  CardContent,
  Typography,
  Box,
  MenuItem,
  FormControl,
  Select,
  Chip,
  Grid,
  Button,
  InputLabel
} from "@mui/material/"
import {getFoiaCaseData} from "../service/services"
import CheckboxList from "../components/checkList"

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const entitySelect = ["Entity Type", "Single Word", "Multiple Entities"];
const binarySelect = ["Highlight", "Redact"];




function getStyles(name, entityName, theme) {
  return {
    fontWeight:
      entityName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function Redact(props) {

  useEffect(() => {
    retrieveData()
    
  }, [])

   // Service Calls
   const retrieveData = () => {
    getFoiaCaseData()
      .then((response) => {
        setState({
          cases: response.data.cases
        })
        console.log(response.data.cases)
      })
      .catch((e) => {
        console.log(e)
      })
   }

   const [caseSelected, setSelectedCase] = React.useState('')


  const theme = useTheme();
  const [entityName, setEntityName] = React.useState([]);
  const [binaryName, setBinaryName] = React.useState([]);
  const [nersName, setNersName] = React.useState([]);

  const [state, setState] = useState({
    isSingleWord: false,
    whichSelectList: "entityType",
    cases: []
  })

    // Handlers

    const handleEntityChange = (event) => {
    console.log(event.target.value);

    if (event.target.value === "Single Word") {
      setState({
        isSingleWord: true,
      });
    }
    if (event.target.value === "Entity Type") {
      setState({
        whichSelectList: "entityType",
      });
    }

    if (event.target.value === "Multiple Entities") {
      setState({
        whichSelectList: "multipleType",
      });
    }

    setEntityName(event.target.value);
  };

  const handleBinaryChange = (event) => {
    console.log(event.target.value);
    setBinaryName(event.target.value);
  };

  const handleNERSChange = (event) => {
    console.log(event.target.value);
    setNersName(event.target.value);
  };

  const handleChipClicked = () => {
    console.info("Chip Clicked");
  };

  const handleChipDelete = () => {
    console.info("Chip Deleted");
    // setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key))
  }

    const handleChange = (event) => {
      setSelectedCase(event.target.value);
    }

  return (
    <div>
      <Grid container xs={12}>
        <Grid item xs={12}>
          <Grid container xs={12} marginTop={"15px"}>
            <Grid item xs={3}>
              <Typography
                fullWidth
                variant="body1"
                component="div"
                color={"#006bb5"}
                fontWeight="bold"
              >
                Select FOIA Case:
              </Typography>
            </Grid>

            <Grid item xs={9}>

              <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Select Case Number</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={caseSelected}
                        label="Select Case Number"
                        onChange={handleChange}
                      >
                        {
                          state.cases.map((caseNum)=>{
                            return (
                              <MenuItem value={caseNum[0].casenumber}>{caseNum[0].casenumber}</MenuItem>
                            )
                          })
                        }

                  
                      </Select>
                  </FormControl>

            </Grid>

            <div className="spacer" />
            <div className="spacer" />

            <Grid item xs={3}>
              <Typography
                fullWidth
                variant="body1"
                component="div"
                color={"#006bb5"}
                fontWeight="bold"
              >
                Case Files:
              </Typography>
            </Grid>

            <Grid item xs={9}  style={{height: '400px', overflowY: 'scroll'}}>

              <CheckboxList
                data={state.cases}
              />
            </Grid>

            <div className="spacer" />
            <div className="spacer" />

            <Grid item xs={3}>
              <Typography
                fullWidth
                variant="body1"
                component="div"
                color={"#006bb5"}
                fontWeight="bold"
              >
                Ways to Redact:
              </Typography>
            </Grid>

            <Grid item xs={9}  >
              <Select
                className="inputBox"
                fullWidth
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                value={entityName}
                onChange={handleEntityChange}
                MenuProps={MenuProps}
               
              >
                {entitySelect.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    style={getStyles(name, entityName, theme)}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <div className="spacer" />
            <div className="spacer" />

            <Grid item xs={3}>
              <Typography
                fullWidth
                variant="body1"
                component="div"
                color={"#006bb5"}
                fontWeight="bold"
              >
                Redact or Highlight:
              </Typography>
            </Grid>

            <Grid item xs={9}>
              <Select
                fullWidth
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                value={binaryName}
                onChange={handleBinaryChange}
                MenuProps={MenuProps}
                className="inputBox"
              >
                {binarySelect.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    style={getStyles(name, binaryName, theme)}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <div className="spacer" />
            <div className="spacer" />

            <Grid item xs={3}>
              <Typography f variant="body1" color={"#006bb5"} fontWeight="bold">
                NERS to Redact:
              </Typography>
            </Grid>

            <Grid item xs={9}>
              {state.isSingleWord === true ? (
                <TextField
                  className="inputBox"
                  fullWidth
                  id="outlined-basic"
                  label="Please type a single word"
                  variant="outlined"
                />
              ) : (
                <Select
                  className="inputBox"
                  fullWidth
                  multiple
                  value={nersName}
                  onChange={handleNERSChange}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={value}
                          onDelete={handleChipDelete}
                          onClick={handleChipClicked}
                        />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {state.whichSelectList === "entityType"
                    ? state.cases.map((name) => (
                        <MenuItem
                          key={name}
                          value={name}
                          style={getStyles(name, nersName, theme)}
                        >
                          {name}
                        </MenuItem>
                      ))
                    : state.cases.map((entity) => (
                        <MenuItem
                          key={entity[0].associatedentities[0]}
                          value={entity[0].associatedentities[0]}
                          style={getStyles(entity[0].associatedentities[0], nersName, theme)}
                        >
                          {entity[0].associatedentities[0]}
                        </MenuItem>
                      ))}
                </Select>
              )}
            </Grid>
          </Grid>

          <div className="spacer" />
          <div className="spacer" />

          <Button
            variant="contained"
            size="large"
            style={{
              margin: "0 auto",
              display: "flex",
              justifyContent: "center",
              color: "#006bb5",
              backgroundColor: "#bcd63e",
              fontWeight: "bold",
            }}
          >
            Begin Redboxing
          </Button>

          <div className="spacer" />
          <div className="spacer" />
        </Grid>
      </Grid>
    </div>
  );
}

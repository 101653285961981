import React, { useState } from "react"
import { QueryBuilder } from 'react-querybuilder'
import axios from "axios"
import 'react-querybuilder/dist/query-builder.scss'
import {
  Grid,
  Button,
  ListSubheader,
  Typography
} from "@mui/material/"
import {QueryResultsList} from "../components/list"
import {  ADD_ICON } from "../assets/icons/IconList"


export default function CentcomQueryBuilder(props) {

  const [queryItem, setQuery] = useState({
    combinator: 'and',
    rules: [
      { field: 'id', operator: '>', value: '10' }
    ],
  })

  const [queryResponse, setQueryResponse] = React.useState([])

  const [loadingEntities, setLoadingEntities] = React.useState(false)
  const [showCards, setShowCards] = React.useState(false)
  const [selectedFiles, setSelectedFiles] = React.useState([])
  const [showSelectedButton, setShowSelectedButton] = React.useState(false)
  const [processMessage, setProcessMessage] = React.useState(false)

    const fields = [{"name":"id","label":"id"},{"name":"name","label":"name"},{"name":"path","label":"path"},{"name":"datecreated","label":"date created"},{"name":"author","label":"author"},{"name":"organization","label":"organization"},{"name":"classification","label":"classification"},{"name":"text","label":"text"}]

    const handleQueryBuilder = () => {
      setLoadingEntities(true)
      setShowCards(false)
      console.log('Data',parseQuery(queryItem,0))
     
      // Call Service
      const page = {
        limit: 45,
        offset: 0
      }

      let filter =  parseQuery(queryItem,0)
      console.log('Filter',filter)

      axios.post(`https://centcomservices.ais.saicds.com:8086/koverse/query`, {
        datasets: ["centcomfullmetadata_20230206_224137_447"],
        filter: filter,
        page: page
    
        }).then(response => {
          console.log('Extract data',response.data)
          setLoadingEntities(false)
          setShowCards(true)
          setQueryResponse(response.data)
          setShowSelectedButton(true)
        
        })
        .catch(error => {
            console.log(error)
        })

    }

    const parseQuery = (filter, counter) => {
      console.log("parse query")
      let combinator = filter.hasOwnProperty('combinator') ? filter.combinator : null;
      let rules = filter.hasOwnProperty('rules') ? filter.rules : null;
      
      if (combinator && rules) {
          let result =  parseQueryRules(combinator, rules, counter);
          return result
      }

      return null;
   }


    const parseQueryRules = (combinator, rules, counter) => {

      let newCondition = (combinator) ? combinator.toUpperCase() : "AND";
      let newRules = [...rules];
      if (rules) {
          for (var i = rules.length - 1; i >= 0; i--) {
              let jsonObj = rules[i];
              if (jsonObj.hasOwnProperty('rules')) {
                  // nested condition
                 
                  let result =  parseQuery(jsonObj, (counter + 1));
                  newRules[i] = result;
              } else {
                  // required
                  let field = jsonObj.hasOwnProperty('field') ? jsonObj.field : null;
                  let value = jsonObj.hasOwnProperty('value') ? jsonObj.value : null;
                  let operator = jsonObj.hasOwnProperty('operator') ? jsonObj.operator.toLowerCase() : '=';
                  let notCondition = false;
                  if (operator === '!=') {
                      operator = '=';
                      notCondition = true;
                  }
                  if (field) {
                      let result = {
                          field: field,
                          operator: operator,
                          value: value
                      }
                      if (notCondition) {
                          result['not_condition'] = true;
                      }
                      newRules[i] = result;
                  }
              }
          }
      }

      let result = {
          condition: newCondition,
          rules: newRules
      }
      return result;
    }

    const handleFileSelection = (files) => {
      console.log(files)
      setSelectedFiles([...selectedFiles, files])
    
    }


    const postAddSelectedFiles = () => {

      axios
        .post(`https://centcomservices.ais.saicds.com:8085/addfilesbycase/`, {
          casenumber: sessionStorage.getItem("CaseNumber"),
          files: selectedFiles
        })
        .then((response) => {
          console.log("Selected Files: ", response.data)
          setTimeout(() => {
            setProcessMessage(true)
          }, 500)
        })
        .catch((error) => {
          console.log(error);
        })
  
    }
  
  

 
 return (
    <div className="queryBuilderContainer" > 
      <Grid container xs={12}  >
     

            <Grid item xs={12} >
              {loadingEntities === true ? (
                     
                            <div class="loaderRing">
                            SEARCHING
                            <span></span>
                          </div>
                          ) : (
                            ""
              )}
            </Grid>

            <Grid item xs={10} className="marginCenter"  >

                <QueryBuilder
                    query={queryItem} 
                    // onQueryChange={q => setQuery(q)}
                    onQueryChange={setQuery}
                    fields={fields} 
                />

            </Grid>

            <Grid item xs={2} className="marginCenter" >
               <div className="spacer" />
               <div className="spacer" />
              <Button 
                onClick={handleQueryBuilder}
                variant="contained"
              >Search</Button>
            </Grid>

              <div className="spacer" />
          
        </Grid> 

        <Grid item xs={12} style={{  margin: '0 auto', textAlign: 'center', justifyContent: 'center'}}  >
           {processMessage === true ? (
              <div ><Typography className="messageBubble bottom floating">
                Files have been successfully added to 
                Case number <b style={{ color: "#e6ff6c" }}>{sessionStorage.getItem("CaseNumber")}</b> 
                </Typography>
                </div>
            ) : (
              ""
            )}
          </Grid>

        <Grid item xs={12} style={{ padding: '25px'}}>
              {showSelectedButton === true ? 
                <Button
                  variant="contained"
                  size="large"
                  style={{
                  margin: "0 auto",
                  display: "flex",
                  justifyContent: "center",
                  color: "#006bb5",
                  backgroundColor: "#bcd63e",
                  fontWeight: "bold",
                  width: '225px',
                  fontSize: '14px',
                  padding: '10px'
                }}
                onClick={postAddSelectedFiles}
              >
                <ADD_ICON /> &nbsp; Selected Files
              </Button>: ''}
              
   
          </Grid>
          <Grid item className="floating" >
              {
                showCards === true ?  <span className="searchResultsBubble bottom" >
                Search results 
                ({queryResponse[0].records.length})
            
                </span>
              : ''
              }  
              <div className="spacer" />           
          </Grid>

        <Grid container xs={12} spacing={6}   
              sx={{
              marginTop: '25px',
              width: '100%',
              maxWidth: 900,
              position: 'relative',
              overflow: 'auto',
              maxHeight: 1000,
              border: '1px solid #d3d3d3',
              borderRadius: '20px',
              '& ul': { padding: 0 },
               }} 
               className="queryBuilderResultsContainer"
            
             
        >
    
      
          {
           showCards === true ?   
           queryResponse[0].records.map((item)=> {
             return <Grid item xs={12} >
                     
                    <QueryResultsList 
                     name={item.value.name}
                     author={item.value.author}
                     organization={item.value.organization}
                     content={item.value.text}
                     path={item.value.path}
                     Data={item}
                     retrieveFileSelection={handleFileSelection}
                    />
                    <div className="dividerShape2" />
                 </Grid>
               })
               : <span style={{textAlign: 'center', color: '#999', opacity: '0.2', margin: '100px auto', float: 'center', fontSize: '30px'}} >Query Search Results</span>
          }
             
      
       </Grid>


    </div>
  )
}

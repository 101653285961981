import React from "react"
import { Grid, Typography } from "@mui/material/"
import CaseDetails from "./content/caseDetails"

export default function caseDetails() {
  return (
    <Grid
      container
      xs={12}
      sm={12}
      md={12}
      lg={12}
      spacing={3}
      className="caseDetailsContainer"
    >
      <Grid item xs={12} className="pageTitle">
        <Typography variant="h5">FOIA Case Details</Typography>
      </Grid>

      <Grid item xs={12} className="marginLeft">
        <CaseDetails />
      </Grid>
    </Grid>
  )
}

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
  Grid,
  Box,
  Typography,
  Button,
  IconButton,
  Avatar,
} from "@mui/material/";

import { BACK_ARROW, ADD_ICON } from "../../assets/icons/IconList";

import Dashboard from "./dashboard";
import UpdateCase from "./updateCase";

const Item = styled(Box)(({ theme }) => ({
  backgroundColor: "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const tabNav = {
  dashboard: "Dashboard",
  update: "Update",
};

export default function CaseDetails(props) {
  const [tab, setTab] = React.useState("Dashboard");
  const [navLabel, setNavLabel] = React.useState("Click for more details");

  //Handlers
  const handlePageToggle = (page) => {
    setTab(page);
  };

  const { id } = useParams();

  return (
    <div className="caseDashboardIndexContainer">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container xs={12} spacing={1}>
          <Grid item xs={2}>
            <Item>
              <Button
                href={"/casedetails"}
                style={{ float: "left", marginTop: "10px" }}
              >
                <BACK_ARROW />
                EXISTING CASES
              </Button>
            </Item>
          </Grid>

          <Grid item xs={10}>
            <Item>
              {tab === "Dashboard" ? (
                <Button
                  style={{ float: "right" }}
                  onClick={(e) => handlePageToggle("Update")}
                >
                  <IconButton>
                    <Typography>UPDATE CASE &nbsp;</Typography>
                    <Avatar style={{ background: "#016bb5" }}>
                      <ADD_ICON iconColor={"white"} />
                    </Avatar>
                  </IconButton>
                </Button>
              ) : (
                <Button
                  style={{ float: "right" }}
                  onClick={(e) => handlePageToggle("Dashboard")}
                >
                  <IconButton>
                    <Typography>DASHBOARD &nbsp;</Typography>
                    <Avatar style={{ background: "#016bb5" }}>
                      <BACK_ARROW iconColor={"white"} />
                    </Avatar>
                  </IconButton>
                </Button>
              )}
            </Item>
          </Grid>

          <div className="spacer" />

          {tab === "Dashboard" ? <Dashboard /> : <UpdateCase />}
        </Grid>
      </Box>
    </div>
  );
}

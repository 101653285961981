import axios from "axios"

const BASE_URL = process.env.REACT_APP_BASE_URL;

// GET FOIA Case data
const getFoiaCaseData = () => {
  return axios.get(`${BASE_URL}cases/`, { crossdomain: true });
};

// GET FOIA Case data filtered by case number
const getFoiaCaseByNumber = (number) => {
  return axios.get(`${BASE_URL}cases/${number}`, { crossdomain: true });
}

// GET Extracted Entities
const getCaseDetails = (text) => {
  return axios.get(`https://centcomservices.ais.saicds.com:8085/cases/${text}`, { crossdomain: true });
}

// Search Page Query
const postQuerySearch = (data) => {
  console.log("Data: ",data)

  const payload = {
    query_text: data.questionSearch,
    mode: data.mode,
    count: 5,
    index: "centcom",
    score_filter: 0.2

  }

  console.log("Inside Search service")
  

  // axios.put(`${BASE_URL}search/`, {
  
  //   query_text: "who used russian weapons",
  //   mode: "generative",
  //   count: 5,
  //   index: "centcom",
  //   score_filter: 0.2

  //   }).then(response => {
  //     console.log(response.data)
  //   })
  //   .catch(error => {
  //       console.log(error)
  //   })

}


// PUT redact
const postRedaction = () => {
  return axios.get(`${BASE_URL}redact/`, { crossdomain: true });
};

// POST new FOIA Case
const postNewFoiaCase = (data) => {
  console.log("Data: ", data.caseNumber);

  axios
    .post(`${BASE_URL}cases/`, {
      assigneduser: "Garret Dechellis",
      associatedentities: ["china", "winnie the poo"],
      casefiles: [
        "/files/17/Q3/BGRMA7AFGN020/F/TFP04/PAL_Share/Rapid Equipping Force (REF)/Lead Lab Engineer/LPWS/LPWS Drawings/8355321C.pdf8355321C.pdf",
      ],
      casenumber: data.caseNumber,
      receivedon: "1/5/2023",
      s3path: "s3://bucket-name/1115",
      status: "Hold",
      trackingcomments: "This request is pretty standard, etc. etc.",
    })
    .then((response) => {
      console.log(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
}

export {getFoiaCaseData,
      getCaseDetails,
        getFoiaCaseByNumber,
        postNewFoiaCase,
        postRedaction,
        postQuerySearch
   }

import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { MORE_VERTICAL_ICON } from "../assets/icons/IconList";

const ITEM_HEIGHT = 55;

export default function PrimaryMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="menuContainer">
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MORE_VERTICAL_ICON iconSize={"35px"} iconColor={"#fff"} />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 5.5,
            width: "25ch",
            color: "#4299f7",
          },
        }}
      >
        <MenuItem onClick={handleClose}>
          <a href="/">Home</a>
        </MenuItem>

        <MenuItem onClick={handleClose}>
          <a href="/foia">Create FOIA Case</a>
        </MenuItem>

        <MenuItem onClick={handleClose}>
          <a href="/casedetails">Existing Cases</a>
        </MenuItem>

      </Menu>
    </div>
  );
}

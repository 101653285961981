import React from "react"
import { Grid, Typography } from "@mui/material/"
import CentcomQueryBuilder from "./content/queryBuilder"

export default function QueryBuilder() {
  return (
    <Grid
      container
      xs={12}
      sm={12}
      md={12}
      lg={12}
      spacing={3}
      className="foiaContainer"
    >
      <Grid item xs={12} className="pageTitle">
        <Typography variant="h5">Search to add files to case <b>{sessionStorage.getItem("CaseNumber")}</b></Typography>
      </Grid>

      <Grid item xs={10} className="marginCenter">

        <CentcomQueryBuilder />

      </Grid>

    </Grid>
  )
}

import React from "react"

import Dashboard from "./content/dashboard"

export default function HomePage() {

  return (

        <>
          <Dashboard />
        </>
    
  )
}

import React, { useState, useEffect, useReducer } from "react";
import {
  Grid,
  TextField,
  FormControlLabel,
  FormHelperText,
  Select,
  MenuItem,
  ListSubheader,
  Button,
  InputAdornment,
  Typography,
  Slider,
  Box
} from "@mui/material/"
import axios from "axios"
import { getFoiaCaseData} from "../service/services"
import { useForm, Controller } from "react-hook-form"
import { SEARCH_ICON, ADD_ICON } from "../assets/icons/IconList"
import {SearchResultsList} from "../components/list"
import ConfidenceSlider from "../components/slider"

export default function Search() {


  useEffect(() => {
    retrieveData()

  
  }, [])


  const [caseSelected, setSelectedCase] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [showSearchResults, setShowSearchResults] = React.useState(false)
  const [showSelectedButton, setShowSelectedButton] = React.useState(false)
  const [selectedFiles, setSelectedFiles] = React.useState([])
  const [processMessage, setProcessMessage] = React.useState(false)
  const [confidenceGage, setConfidenceGage] = React.useState()

  const [state, setState] = useState({
    data: [],
    cases: [],
    question: "",
    showCards: false,
    filterOption: "extractive",
    extractiveView: false,
    generativeView: false,
    dropDownValue: "",
    hideTyping: false,
    query_text: "",
    mode: "",
    count: "",
    index: "",
    score_filter: 0.2,
    loading: false,
    selectedFOIACase: "1111",
  })



  // Service Call
  const retrieveData = () => {
    getFoiaCaseData()
      .then((response) => {
        setState({
          cases: response.data.cases,
        });
        console.log(response.data.cases);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const [searchQuery, setSearchQuery] = React.useState([]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const initForm = {
    data: [],
    query_text: "",
    mode: "",
    count: "",
    index: "",
    score_filter: 0.2,
  };

  const [formVal, dispatch] = useReducer(
    (curVal, newVal) => ({ ...curVal, ...newVal }),
    initForm
  );

  const handleIndexChange = (event) => {
    console.log(event.target.value);
    setSelectedCase(event.target.value);
    setShowSearchResults(true);
  };

  // Service Calls
  const postQuerySearch = (data) => {
      console.log("Data: ",data)
      console.log("Score: ",confidenceGage)

      setLoading(true)
 
        axios.post(`https://centcomservices.ais.saicds.com:8000/query`, {
        
          query: data.questionSearch,
          threshold: confidenceGage,
          params: {"Retriever" : {"top_k": 250}, "Reader" : {"top_k": 250}},
          debug: false

        
          }).then(response => {
            console.log(response.data)
            setLoading(false)
            setSearchQuery(response.data.answers)
            setShowSearchResults(true)
            setShowSelectedButton(true)
          
        
          })
          .catch(error => {
              console.log(error)
          })

  }

  const postAddSelectedFiles = () => {

    axios
      .post(`https://centcomservices.ais.saicds.com:8085/addfilesbycase/`, {
        casenumber: sessionStorage.getItem("CaseNumber"),
        files: selectedFiles
      })
      .then((response) => {
        console.log("Selected Files: ", response.data)
        setTimeout(() => {
          setProcessMessage(true)
        }, 500)
      })
      .catch((error) => {
        console.log(error);
      })

  }


  const searchHandler = (data) => {
    console.log(data)

    postQuerySearch(data)
  }

  const registerOptions = {
    searchInput: {
      required: "Please enter a valid query",
      minLength: {
        value: 3,
        message: "Search query must be at least 3 characters in length",
      },
    },
  }


  // Call backs
  const handleFileSelection = (files) => {

    setSelectedFiles([...selectedFiles, files])
  
  }

  const handleConfidenceSelection = (score) => {

    setConfidenceGage(score)
  }

 

  return (
    <div className="searchContainer">
      <Grid container xs={12}>
          <Grid item xs={6} style={{ margin: '0 auto'}}>
            <FormHelperText className="floating" style={{margin: '-70px'}}  >
              {errors?.questionSearch ? (
                <span className="validationBubble bottom" >
                  {errors.questionSearch.message}
                </span>
              ) : null}
            </FormHelperText>
          </Grid>

    
        <Grid item xs={12} >
       
          {/* Search Bar */}
          <Grid container xs={12} spacing={1}>
            <Grid item xs={7} className="search-box">
              <TextField
                className="input"
                sx={{
                  "& fieldset": { border: "none" },
                }}
                autoFocus
                placeholder="Search ..."
          
                id="input"
                name="questionSearch"
                onChange={(e) => e.target.value}
                disableUnderline
                {...register("questionSearch", registerOptions.searchInput)}
                startAdornment={
                  <InputAdornment position="start">
                    <SEARCH_ICON iconColor={"#016bb5"} iconSize={"30px"} />
                  </InputAdornment>
                }
              />
            </Grid>

            {/* Search Button */}
            <Grid item xs={1}>
              <Button
                type="submit"
                variant="contained"
                onClick={handleSubmit(searchHandler)}
                className="searchButton"
              >
                <SEARCH_ICON />
              </Button>
            </Grid>

            <Grid item xs={12} style={{  margin: '0 auto', textAlign: 'center', justifyContent: 'center'}}  >
           {processMessage === true ? (
              <div ><Typography className="messageBubble bottom floating">
                Files have been successfully added to 
                Case number <b style={{ color: "#e6ff6c" }}>{sessionStorage.getItem("CaseNumber")}</b> 
                </Typography>
                </div>
            ) : (
              ""
            )}
          </Grid>

          <Grid item style={{margin: '-10px auto'}} >
      
           
                <ConfidenceSlider
                  retrieveConfidenceGage={handleConfidenceSelection}
                />

          </Grid>

   
            <Grid item xs={12} style={{ padding: '25px'}} >
              {showSelectedButton === true ? 
                <Button
                  variant="contained"
                  size="large"
                  style={{
                  margin: "25px auto",
                  display: "flex",
                  justifyContent: "center",
                  color: "#006bb5",
                  backgroundColor: "#bcd63e",
                  fontWeight: "bold",
                  width: '225px',
                  fontSize: '14px',
                  padding: '10px'
                }}
                onClick={postAddSelectedFiles}
              >
                <ADD_ICON /> &nbsp; Selected Files
              </Button>: ''}
              
   
          </Grid>

            <div className="spacer" />
         

                    {/* Search Results */}
                    <Grid container xs={12} spacing={3} 
                    sx={{
                        width: '100%',
                        maxWidth: 900,
                        position: 'relative',
                        overflow: 'auto',
                        maxHeight: 1000,
                        borderRadius: '20px',
                        marginTop: '-55px',
                        '& ul': { padding: 0 },
                        }} 
                      
                        
                        >
                      <Grid item xs={12} >
                      {loading === true ? (
                            <div class="loadingContainer">
                              <div class="loading">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                              </div>
                            </div>
                          ) : (
                            ""
                        )}
                      </Grid>
                      <Grid item xs={10} className="floating" >
                        {
                          showSearchResults === true ?  <span className="searchResultsBubble bottom" >
                            Search results 
                            ({searchQuery.length})
                        
                            </span>
                          : ''
                        }
                      </Grid>

                      <div className="spacer" />

                      {showSearchResults === true ? 
      
                                searchQuery.map((item)=>{
                                  
                                    return <Grid item xs={10} className="marginCenter fadePanel" >
                                              <SearchResultsList 
                                                  FileName={item.Name}
                                                  Score={item.Score}
                                                  Context={item.Context}
                                                  Path={item.Meta.path}
                                                  Data={item}
                                                  retrieveFileSelection={handleFileSelection}
                                                />
                                                <div className="dividerShape2" />
                                            </Grid>
                                }

                                )
                                
                      
                            : <span></span>
    
                      }
                    </Grid>

                </Grid>


            </Grid>
          </Grid>
    
  
    </div>
  )
}

import React from "react"
import { Grid, Typography } from "@mui/material/"
import Redact from "./content/redact"

export default function redaction() {
  return (
    <Grid
      container
      xs={12}
      sm={12}
      md={12}
      lg={12}
      spacing={3}
      className="redactContainer"
    >
      <Grid item xs={12} className="pageTitle">
        <Typography variant="h5">Redaction Redboxing</Typography>
      </Grid>

      <Grid item xs={6} className="marginCenter">
        <Redact />
      </Grid>
    </Grid>
  )
}

import * as React from 'react'
import {List,
    ListItem,
    ListItemButton,
    ListItemText,
    Checkbox

} from '@mui/material/'

export default function CheckboxList(props) {
  const [checked, setChecked] = React.useState([1])

  const handleToggle = (value) => () => {

    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  // const truncateFileName = (str) => {
  //   str = JSON.stringify(str)
  //   return str.length > 10 ? str.substring(2, 65) + "..." : str;
  // }

  return (
    <List dense sx={{ width: '100%', maxWidth: 700 }}>
      {/* {console.log(props.data)} */}

       {/* {props.data.map((cases) => {
         const labelId = `checkbox-list-secondary-label-${cases.casefiles}`
          const fileName = truncateFileName(cases[0].casefiles)
         return (
 
                  
  
          <ListItem
            key={cases.casefiles}
            secondaryAction={
              <Checkbox
                edge="end"
                onChange={handleToggle(cases.casefiles)}
                checked={checked.indexOf(cases.casefiles) !== -1}
                inputProps={{ 'aria-labelledby': labelId }}
              />
            }
            disablePadding
          >
            <ListItemButton>
              <ListItemText id={labelId} primary={fileName} />
            </ListItemButton>
          </ListItem>
        )
      })} */}
    </List>
  )
}